import { Component, OnInit, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IEmbedConfiguration, Report } from 'powerbi-client';
import { NgxPowerBiService } from 'ngx-powerbi';
import * as models from 'powerbi-models';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BotService } from 'src/services/bot.service';
import { UsersService } from 'src/services/User-services/user-services';
import { CLIENT_INFO, INVALID_TEAM, RETAILER_TEAM_CODE, USER_ID, VENDOR_TEAM_CODE } from 'src/common/keys';
import { ItemManagerService } from 'src/services/Item-Management-Services';
import { MatDialog } from '@angular/material/dialog';
import { SaveJourneyComponent } from '../context-log-journey/save-journey/save-journey.component';
import { CreateJourneyComponent } from '../context-log-journey/create-journey/create-journey.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { JourneyModel } from '../context-log-journey/journey.model';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ContextLogModel } from '../context-log-journey/contextLog.model';
import { JourneyCommentComponent } from '../context-log-journey/journey-comment/journey-comment.component';
import { JourneyShareComponent } from '../context-log-journey/journey-share/journey-share.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExistingJourneyComponent } from '../context-log-journey/existing-journey/existing-journey.component';
import { FormControl } from '@angular/forms';
import { privilegeOptions } from '../../constant/privilegeOptions';
import { datetimeLocalzone } from 'src/common/util/util';

@Component({
  selector: 'app-dynamic-report',
  templateUrl: './dynamic-report.component.html',
  styleUrls: ['./dynamic-report.component.scss']
})
export class DynamicReportComponent implements OnInit {

  @Input() data: any;
  @Input() pageobject: any;
  txtEmbedGroupId = '';
  txtEmbedReportId = '';
  txtEmbedDatasetId = '';
  embedReportContainerId = '';
  tokenInfo;
  reportFilters;
  reportMode: 'create' | 'save' = 'create';
  stages: { Id?: number, name?: string, actualName?: string, reportId?: string, groupId?: string, IsDeleted?: boolean }[] = [{ name: 'Default' }];
  selectedIndex: number = 0;
  isAddToExistingOnCreate: boolean = false;
  selectedExistingJourney: any;

  comment: FormControl = new FormControl();
  comments: any[] = [];
  mentionConfig = {
    triggerChar: '@',
    labelKey: 'email',
    dropUp: true,
    allowSpace: true,
    mentionSelect: (event) => {
      return `@${event.firstName} ${event.lastName}`
    }
  };
  userList: any = [];

  VENDOR_TABLE_NAME = 'DimItem';
  VENDOR_FIELD_NAME = 'VendorName';

  DATE_COLUMNS = [
    'DimItem/ReleaseDate',
    'V_Promotion_Mart/promoSku_streetdate__c',
    'V_Promotion_Mart/promoSku_base_cost_effective_date__c',
    'V_Promotion_Mart/base_cost_effective_date__c',
    'V_Promotion_Mart/promo_startdate__c',
    'V_Promotion_Mart/promo_enddate__c',
    'V_Promotion_Mart/to_date__c',
    'V_Promotion_Mart/from_date__c',
    'DimDate/Date'
  ];

  private report: Report;
  private powerBiService: NgxPowerBiService;
  private pbiContainerElement: HTMLElement;
  private filterPaneEnabled = false;
  private navContentPaneEnabled = true;
  private enablePersistentFilters = true;
  isBotFiltersApplied = false;

  accountList = [];
  accountId: any;
  userTeam: any = '';
  isRetailerTeam: boolean = false;
  journeyName: any;
  tempContextTitle: any;

  private readonly reportNamePrefix = '@algo-journey~';
  journeyId: number;
  showContainer: boolean = true;
  reportLoaded: boolean = false;
  createdBy: number;

  commentSection: boolean = false;
  privilege = '';
  getDatetimeByZone = datetimeLocalzone;

  constructor(
    public botService: BotService,
    private usersService: UsersService,
    private itemManagerService: ItemManagerService,
    private dialog: MatDialog,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document) {
    this.powerBiService = new NgxPowerBiService();
  }

  ngOnInit() {
    this.getFormContorlsData();
    this.initiateValues();
    this.setRoleBaseAccess();
    this.getUserList();
    if (this.data) {
      const report = JSON.parse(this.data);
      this.reportFilters = report.reportFilters;
      this.txtEmbedReportId = report.reportId;
      this.txtEmbedGroupId = report.groupId;
      this.embedReportContainerId = `${report.reportId}-${moment.now().toString()}`;
      this.txtEmbedDatasetId = report.datasetId;
      console.log("BBY - OverView", this.data);
    } else if (this.pageobject) {
      const report = JSON.parse(this.pageobject);
      this.reportFilters = report?.reportFilters;
      this.txtEmbedReportId = report?.originalReportId;
      this.embedReportContainerId = `${report?.reportId}-${moment.now().toString()}`;
      this.journeyId = report.journeyId;
      this.reportMode = 'save';
      this.createdBy = report.createdBy
      this.journeyName = report.journeyName;
      // this.txtEmbedDatasetId = report?.originalGroupId;
      this.txtEmbedGroupId = report?.originalGroupId;
      this.privilege = report.privilege || '';
      console.log("PageObject", this);
      this.getContextLog();
    }
    const params = {
      reportId: this.txtEmbedReportId,
      groupId: this.txtEmbedGroupId
    };
    this.stages[0].reportId = this.txtEmbedReportId;
    this.stages[0].groupId = this.txtEmbedGroupId;
    /*
    JS PowerBI SDK
    */
    this.loadReportViaSDK(params);
  }

  async getFormContorlsData() {
    await this.itemManagerService.GetAllAccounts().toPromise();
  }

  initiateValues() {
    this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      this.accountList = accountList;
    });
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.accountId = this.usersService.getTeamRoleAccount();
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  getUserList = () => {
    this.usersService.GetAllUsers({ userTypeId: 1 }).subscribe((res) => {
      this.userList = (res as any).data;
    });
  };

  loadReportViaSDK(params) {
    this.botService.GetMicrosoftLoginToken(params).subscribe(async (res: any) => {
      this.tokenInfo = res.access_token;
      const filters = this.getAdvancedFiltersList(this.reportFilters);
      this.isBotFiltersApplied = filters.length ? true : false;
      const roleBaseFilter = this.getRoleBaseFilter();
      roleBaseFilter && filters.push(roleBaseFilter);
      const config: IEmbedConfiguration = {
        permissions: models.Permissions.Copy,
        accessToken: this.tokenInfo,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.txtEmbedReportId}&groupId=${this.txtEmbedGroupId}`,
        filters: filters,
        id: this.txtEmbedReportId,
        settings: {
          filterPaneEnabled: this.filterPaneEnabled,
          navContentPaneEnabled: this.navContentPaneEnabled,
          persistentFiltersEnabled: this.enablePersistentFilters
        },
        viewMode: models.ViewMode.View,
        tokenType: models.TokenType.Aad,
        type: 'report',
      };
      this.pbiContainerElement = <HTMLElement>(document.getElementById(this.embedReportContainerId));
      this.report = <Report>this.powerBiService.embed(this.pbiContainerElement, config);
      this.report.on('loaded', (event: any) => {
        console.log(event);
        this.reportLoaded = true;
        this.spinner.hide();
      });
      this.report.on("saved", (event: any) => {
        if (this.isAddToExistingOnCreate) {
          const contextLogModel: ContextLogModel = new ContextLogModel();
          contextLogModel.ActualName = event?.detail?.reportName;
          contextLogModel.DisplayName = this.tempContextTitle;
          contextLogModel.GroupId = this.txtEmbedGroupId;
          contextLogModel.JourneyId = this.selectedExistingJourney.journeyId;
          contextLogModel.ReportId = event?.detail?.reportObjectId
          contextLogModel.ClientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
          contextLogModel.Instance = this.document.baseURI;
          this.tempContextTitle = '';
          this.configService.createContextLog(contextLogModel).subscribe((contextLogId: number) => {
            this.isAddToExistingOnCreate = false;
            this.toastr.success(
              `Existing Journey Updated`,
              `Log Saved: ${contextLogModel.DisplayName}`
            );
          });
        } else {
          this.showContainer = false;
          console.log("saved", event);
          const existingItems = this.stages.filter((stage: any) => {
            if (stage.reportId === event?.detail?.reportObjectId)
              return true;
          });
          if (existingItems?.length > 0) {
            return;
          }
          this.stages.push({ name: this.tempContextTitle, actualName: event?.detail?.reportName, reportId: event?.detail?.reportObjectId, groupId: event?.target?.powerBiEmbed?.config?.groupId });
          console.log(this.stages);
          const contextLogModel: ContextLogModel = new ContextLogModel();
          contextLogModel.ActualName = event?.detail?.reportName;
          contextLogModel.DisplayName = this.tempContextTitle;
          contextLogModel.GroupId = this.txtEmbedGroupId;
          contextLogModel.JourneyId = this.journeyId;
          contextLogModel.ReportId = event?.detail?.reportObjectId
          contextLogModel.ClientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
          contextLogModel.Instance = this.document.baseURI;
          this.tempContextTitle = '';
          this.configService.createContextLog(contextLogModel).subscribe((contextLogId: number) => {
            this.toastr.success(
              `Journey Updated`,
              `Log Saved: ${contextLogModel.DisplayName}`
            );
            contextLogModel.Id = contextLogId;
            this.selectedIndex = this.stages.length - 1;
            this.stages[this.selectedIndex].Id = contextLogId;
          })
        }
      });
    });
  }

  async saveReport(reportName: any) {
    await this.report.saveAs({ name: reportName/*, targetWorkspaceId: '8353f0cb-426a-4017-8a2b-9e5cab714e7f'*/ });
  }

  getAllJourneys() {
    this.spinner.show();
    this.configService
      .GetAllJourneys()
      .subscribe((res: any[]) => {
        this.spinner.hide();
        if (res && res.length) {
          this.openExistingJourneyMode(res);
        } else {
          this.toastr.info('Info', 'Journeys does not exist.');
        }
      });
  }

  openExistingJourneyMode(journeysList) {
    const dialogRef = this.dialog.open(ExistingJourneyComponent, {
      restoreFocus: false,
      data: {
        journeysList: journeysList,
        selectedSubJourney: this.stages[this.selectedIndex],
        reportMode: this.reportMode
      },
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(async (selectedJourney) => {
      if (selectedJourney) {
        this.isAddToExistingOnCreate = true;
        this.selectedExistingJourney = selectedJourney;
        this.tempContextTitle = JSON.parse(this.pageobject).name;
        await this.saveReport(this.reportNamePrefix + this.tempContextTitle);
      }
    });
  }

  createJourneyMode($event: any) {
    // alert('here')

    const dialogRef = this.dialog.open(CreateJourneyComponent, {
      restoreFocus: false,
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((journey: JourneyModel) => {
      console.log(journey);
      if (journey) {
        this.isAddToExistingOnCreate = false;
        this.selectedIndex = this.stages.length - 1;
        this.reportMode = 'save';
        this.journeyName = journey.journeyName;
        // save journey to database and get journeyId in this.journeyId
        journey.clientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
        journey.instance = this.document.baseURI;
        journey.createdBy = this.storage.get(USER_ID);
        journey.originalGroupId = this.txtEmbedGroupId;
        journey.originalReportId = this.txtEmbedReportId;
        journey.originalReportName = JSON.parse(this.data).reportName;
        journey.metaData = typeof this.pageobject === 'string' ? this.pageobject : JSON.stringify(this.pageobject);

        this.configService.createJourney(journey).subscribe((journeyId: number) => {
          this.journeyId = journeyId;
          this.createdBy = journey.createdBy;
          this.toastr.success(
            `Started Journey`,
            `${this.journeyName}`
          );
        });
      }
    });
  }

  async saveJourney() {
    if (this.selectedIndex == 0) {
      this.toastr.warning('Warning', 'Default view cannot be overwritten.');
      return;
    }
    this.spinner.show();
    this.report.savePersistentFilters().then(() => {
      this.spinner.hide();
      this.toastr.success('', 'Report saved successfully.');
    })
      .catch((err) => {
        console.log('ERROR HERE', err);
        this.spinner.hide();
      });
  }

  async saveAsJourney() {
    const dialogRef = this.dialog.open(SaveJourneyComponent, {
      restoreFocus: false,
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(async (contextLogName: string) => {
      if (contextLogName) {
        this.tempContextTitle = contextLogName;
        await this.saveReport(this.reportNamePrefix + this.tempContextTitle);
      }
    });
  }

  clearReportFilter() {
    const roleBaseFilter = this.getRoleBaseFilter();
    this.report.setFilters(roleBaseFilter ? [roleBaseFilter] : []);
    this.isBotFiltersApplied = false;
  }

  getAdvancedFiltersList(reportFilters) {
    const advancedFilterList: any = [];
    let filter = reportFilters ? reportFilters.split(';') : '';
    if (filter.length > 1) {
      filter.shift();
    }
    filter = _.compact(filter);
    filter = _.filter(filter, value => !value.includes('Measure'));
    const fieldsMapping: any = [];
    filter.forEach(field => {
      let matchRgx = field.match(/(\[(.)+\])/g);
      if (matchRgx) {
        matchRgx = matchRgx[0]
        field = field.replace(matchRgx, `/${matchRgx.replaceAll(/(\[)|(\])/g, '')}`);
      }
      const fieldValueSplit = field.split(':');
      const fieldMeta = _.find(fieldsMapping, a => fieldValueSplit[0].includes(a.field));
      if (fieldValueSplit[0].includes('From-')) {
        if (fieldMeta) {
          fieldMeta.from = fieldValueSplit[1];
        } else {
          fieldsMapping.push({ type: 'range', field: fieldValueSplit[0].replace('From-', ''), from: fieldValueSplit[1] });
        }
      } else if (fieldValueSplit[0].includes('To-')) {
        if (fieldMeta) {
          fieldMeta.to = fieldValueSplit[1];
        } else {
          fieldsMapping.push({ type: 'range', field: fieldValueSplit[0].replace('To-', ''), to: fieldValueSplit[1] });
        }
      } else if (this.DATE_COLUMNS.some((dateField) => { return fieldValueSplit[0] === dateField })) {
        fieldsMapping.push({ type: 'range', field: fieldValueSplit[0], from: fieldValueSplit[1], to: fieldValueSplit[1] });
      } else {

        const fieldValue = fieldValueSplit.slice(1).join(':');
        // const fieldValue = fieldValueSplit[1];
        if (fieldMeta) {
          fieldMeta.values.push(...fieldValue.split('--'));
        } else {
          fieldsMapping.push({ type: 'select', field: fieldValueSplit[0], values: [...fieldValue.split('--')] });
        }
      }
    });
    fieldsMapping.forEach(filter => {
      const fieldSplit = filter.field.split('/');
      switch (filter.type) {
        case 'range': {
          advancedFilterList.push({
            $schema: "http://powerbi.com/product/schema#advanced",
            filterType: models.FilterType.Advanced,
            target: {
              table: fieldSplit[0], // filter table
              column: fieldSplit[1] // filter column
            },
            logicalOperator: 'And',
            conditions: [
              { operator: 'GreaterThanOrEqual', value: filter.from + "T00:00:00" },
              { operator: 'LessThanOrEqual', value: filter.to + "T00:00:00" },
            ]
          });
          break;
        }
        case 'select': {
          const conditions: any = [];
          filter.values.forEach(value => {
            conditions.push({ operator: 'Is', value: value });
          });
          advancedFilterList.push({
            $schema: "http://powerbi.com/product/schema#advanced",
            filterType: models.FilterType.Advanced,
            target: {
              table: fieldSplit[0], // filter table
              column: fieldSplit[1] // filter column
            },
            logicalOperator: 'Or',
            conditions: conditions
          });
          break;
        }
      }
    });
    return advancedFilterList;
  }

  getRoleBaseFilter() {
    if (!this.isRetailerTeam) {
      const account = _.find(this.accountList, a => a.accountId == this.accountId);

      return account ? {
        $schema: "http://powerbi.com/product/schema#advanced",
        filterType: models.FilterType.Advanced,
        target: {
          table: this.VENDOR_TABLE_NAME, // filter table
          column: this.VENDOR_FIELD_NAME // filter column
        },
        logicalOperator: 'Or',
        conditions: [{ operator: 'Is', value: account.accountName }]
      } : null;
    }
    return null;
  }

  selectedIndexChange($event: number) {
    console.log('selectedIndexChange', $event);
    this.spinner.show()
    this.showContainer = false;
    const params = {
      reportId: this.stages[$event].reportId,
      groupId: this.stages[$event].groupId, //this.txtEmbedGroupId
    };
    this.selectedIndex = $event;
    this.txtEmbedReportId = params.reportId;
    this.txtEmbedGroupId = params.groupId;
    this.embedReportContainerId = `${params.reportId}-${moment.now().toString()}`;
    this.showContainer = true;
    this.comments = [];
    this.loadJourneyContextLogComments(this.stages[$event].Id);
    this.loadReportViaSDK(params);
  }

  loadJourneyContextLogComments(journeyContextLogId: number) {
    this.configService.GetJourneyContextLogComments(journeyContextLogId).subscribe((res: any) => {
      if (res && res.length) {
        this.comments = res;
      }
    });
  }

  removeContextLog(tab, $event) {
    if (confirm(`Are you sure, you want to delete context: ${tab.name}`)) {
      tab.IsDeleted = true;
      console.log('removeContextLog', tab);
      tab.clientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
      tab.instance = this.document.baseURI;
      this.configService.DeleteReportByGroupId(tab.groupId, tab.reportId).subscribe((res: any) => {
        this.configService.DeleteContextLog(tab).subscribe((res: any) => {
          this.stages.indexOf(tab);
          this.stages.splice(this.stages.indexOf(tab), 1);
          console.log(this.stages);
          if (this.selectedIndex > 0) {
            this.selectedIndex = this.selectedIndex--;
            this.selectedIndexChange(this.selectedIndex);
          }
          this.toastr.success(
            `Journey Updated`,
            `Log Removed: ${tab.name}`
          );
        });
      });
    }
  }

  postComment($event: any) {
    console.log('comment', $event)
    const dialogRef = this.dialog.open(JourneyCommentComponent, {
      restoreFocus: false,
      width: '800px',
      panelClass: 'journey-comment-dialog',
      data: this.journeyId
    });
  }

  shareJourney($event: any) {
    console.log('share', $event)
    const dialogRef = this.dialog.open(JourneyShareComponent, {
      restoreFocus: false,
      width: '500px',
      data: { journeyId: this.journeyId, createdBy: this.createdBy, sharedBy: this.storage.get(USER_ID) }
    });
  }

  getContextLog() {
    this.configService.GetContextLog(this.journeyId).subscribe((contexts: any[]) => {

      contexts.forEach(context => {
        this.stages.push({ name: context.displayName, actualName: context.actualName, reportId: context.reportId, groupId: context.groupId, Id: context.id });
      });
    })
    console.log("Stages", this.stages)
  }

  isOwner() {
    // Editor or Owner
    return (this.storage.get(USER_ID) === this.createdBy) || (this.privilege == privilegeOptions[0].id);
  }

  isCommenter() {
    return this.privilege == privilegeOptions[2].id;
  }

  isViewer() {
    return this.privilege == privilegeOptions[1].id;
  }

  isMyComment(comment: any) {
    return this.storage.get(USER_ID) === comment.createdBy;
  }

  closeCommentSection() {
    this.commentSection = false;
  }

  viewComments() {
    this.commentSection = true;
  }

  onKeydown(event) {
    event.preventDefault();
  }

  saveComment() {
    console.log(this.comment.value);
    const payload = {
      comment: this.comment.value,
      journeyContextLogId: this.stages[this.selectedIndex].Id,
      clientConfigurationId: this.storage.get(CLIENT_INFO).clientConfigurationId,
      instance: this.document.baseURI
    };
    this.configService.CreateJourneyContextLogComments(payload).subscribe((res: any) => {
      if (res && res.length) {
        this.comments.push(...res);
        this.comment.setValue('');
      }
    });
  }
}

