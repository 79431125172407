import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TreeviewItem } from 'ngx-treeview';
import { Observable } from 'rxjs';
import { ForecastLocationManagerService } from 'src/services/forecast-services/location-manager.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { CreateLocationHierarchyTree } from 'src/common/location-tree-dropdown';
import { LocationHierarchyService } from 'src/services/setup-services/location-hierarchy.service';
import moment from 'moment';

@Component({
  selector: 'app-location-setup',
  templateUrl: './location-setup.component.html',
  styleUrls: ['./location-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationSetupComponent implements OnInit {
  @Input() data?: any;

  locationHierarchyList: any = [];
  locationSeasonList: any = [];
  locationSourceList: any = [];
  locationSupplierList: any = [];
  locationUdaTypeList: any = [];
  locationUdaList: any = [];
  isEdit: boolean = false;
  locationData: any;
  udaList: any = [];
  likeItemList: any = [];
  filteredItemList: Observable<any[]>;

  public selectedLocationHierarchies = [];
  closingDateMin: Date;

  public locationHierarchyTreeList: TreeviewItem[];



  locationSetupForm = new FormGroup({
    node1: new FormControl(null,),
    node2: new FormControl(null, Validators.required),
    otherInformation: new FormControl(null,),
    launchDate: new FormControl(null),
    closingDate: new FormControl(null),
    active: new FormControl(true),
    locationHierarchyLastLevelId: new FormControl(null, Validators.required),
  });

  constructor(
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    private forecastLocationManagerService: ForecastLocationManagerService,
    public locationHierarchyService: LocationHierarchyService

  ) { }

  ngOnInit() {
    this.addCustomValidators();
    this.getLookupData();
  }

  initEditMode() {
    const data = JSON.parse(this.data ? this.data : {});
    this.isEdit = data.isEdit ? data.isEdit : false;
    if (this.isEdit) {
      this.getLocationSetupById(_.get(data, 'locationData.dimLocationId', 0));
    }
  }

  getLocationSetupById(dimLocationId) {
    this.spinner.show();
    this.forecastLocationManagerService.GetLocationSetupById(dimLocationId).subscribe(response => {
      this.locationData = response;
      this.initializeForm();
      this.spinner.hide();
    });
  }

  initializeForm() {
    this.createLocationHierarchyDropdownList(this.locationHierarchyList, [this.locationData.locationHierarchyLastLevelId]);
    this.locationSetupForm.patchValue(this.locationData);
  }


  addCustomValidators() {
    setTimeout(() => {
      this.locationSetupForm.controls['closingDate'].setValidators([Validators.required, this.closingDateValidator]);
    }, 0);
  }

  closingDateValidator(control: FormControl) {
    let launchDate = control.parent.controls['launchDate'].value;
    const closingDate = control.value;
    if (launchDate) {
      launchDate = new Date(launchDate);
      if (closingDate < launchDate) {
        return {
          closingDate: 'Launch date should be less then Closing date.'
        };
      }
      return null;
    } else {
      return null;
    }
  }
  public getLookupData() {
    this.spinner.show();
    this.forecastLocationManagerService.LocationManagerHierarchyGetList().subscribe(res => {
      this.locationHierarchyList = res;
      this.createLocationHierarchyDropdownList(res);
      this.initEditMode();
      this.spinner.hide();
    });
  }

  createLocationHierarchyDropdownList(locationData, select: any = []) {
    this.locationHierarchyTreeList = CreateLocationHierarchyTree(locationData, select);
  };

  setLocationHierarchyValue(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'LocationHierarchy': {
        this.selectedLocationHierarchies = selectedDropdown.value;
        this.locationSetupForm.get('locationHierarchyLastLevelId').setValue(this.selectedLocationHierarchies[0]);
        break;
      }
    }
  };

  launchDateChange(event) {
    this.setClosingDateMin();
  }

  setClosingDateMin() {
    const launchDate = this.locationSetupForm.get('launchDate').value;
    if (launchDate) {
      this.closingDateMin = new Date(launchDate);
    }
  }

  onSubmit() {
    if (this.locationSetupForm.valid && this.selectedLocationHierarchies.length < 2) {
      this.spinner.show();
      if (this.isEdit) {
        const payload = {
          ...this.locationSetupForm.value,
          dimLocationId: this.locationData.dimLocationId,
        }
        this.forecastLocationManagerService.LocationManagerUpdate(payload).pipe(finalize(() => this.spinner.hide())).subscribe((res: any) => {
          if (res) {
            const code = _.get(res, '0.messageCode', 400);
            const message = _.get(res, '0.messageText', 'Location Setup Manager Update Failed.');
            if (code === 200) {
              this.forecastLocationManagerService.setLocationSetupActionSubject(true);
              this.toastr.success('Success', `${message}`);
            } else {
              res.forEach(element => {
                this.toastr.error('Error', `${element.messageText}`);
              });
            }

          }
        },
          (err: any) => {
            this.toastr.error('Error', 'Location Setup Manager Update Failed.');
          });
      } else {
        this.forecastLocationManagerService.LocationManagerAdd(this.locationSetupForm.value).pipe(finalize(() => this.spinner.hide())).subscribe(
          (res: any) => {
            if (res) {
              const code = _.get(res, '0.messageCode', 400);
              const message = _.get(res, '0.messageText', 'Location Setup Manager Save Failed.');
              if (code === 200) {
                // this.getLocationSetupById(this.locationData.dimLocationId);
                this.forecastLocationManagerService.setLocationSetupActionSubject(true);
                this.toastr.success('Success', `${message}`);
              } else {
                res.forEach(element => {
                  this.toastr.error('Error', `${element.messageText}`);
                });
              }
            }
          },
          (err: any) => {
            this.toastr.error('Error', 'Location Setup Manager Save Failed.');
          });
      }
    } else {
      this.toastr.error('Error', 'Please Fill Form Correctly.')
    }
  }

}
