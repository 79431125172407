import { dateRenderer, statusRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import { tooltipcellRenderer } from "src/common/util/util";
import { SharedDataService } from "src/services/shared-data.service";
import { AppInjector } from "src/shared/shared.module";

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getColDefs = (hierarchyMetadataList: any = [], nodeMetadataList: any = []) => {
  const hierarchyMetadataColDef: any = [];
  const nodeMetadataColDef: any = [];
  hierarchyMetadataList.forEach(item => {
    hierarchyMetadataColDef.push({
      headerName: item.productHierarchyName,
      field: `h${item.productHierarchyLevel}Value`,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    });
  });
  nodeMetadataList.forEach(node => {
    nodeMetadataColDef.push({
      headerName: node.productHierarchyNodeName,
      field: `node${node.productHierarchyNodeLevel}`,
      cellRenderer: 'productItemEditRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    });
  });
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: 'Image',
      field: 'image',
    },
    ...nodeMetadataColDef,
    ...hierarchyMetadataColDef,
    {
      headerName: 'Release Date',
      field: 'releaseDate',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'End Of Life',
      field: 'endofLifeDate',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Cost',
      field: 'cost',

    },
    {
      headerName: 'Regular Retail Price',
      field: 'retailPrice',

    },
    {
      headerName: 'MSRP',
      field: 'msrp',

    },
    {
      headerName: 'Gross Margin',
      field: 'grossMargin',

    },
    {
      headerName: "UDA's",
      field: 'productUDANames',
      cellRenderer: tooltipcellRenderer,
    }
  ];

  return colDefs;
}


export const getExportTemplateColDefs = (hierarchyMetadataList: any = [], nodeMetadataList: any = []) => {
  const hierarchyMetadataColDef: any = [];
  const nodeMetadataColDef: any = [];
  const udaColDef: any = [];

  hierarchyMetadataList.forEach(item => {
    hierarchyMetadataColDef.push({
      headerName: item.productHierarchyName,
      children: [{
        headerName: `H${item.productHierarchyLevel}`,
        field: `h${item.productHierarchyLevel}Value`
      }]

    });
  });
  nodeMetadataList.forEach(node => {
    nodeMetadataColDef.push({
      headerName: node.productHierarchyNodeName,
      children: [{
        headerName: `Node${node.productHierarchyNodeLevel}`,
        field: `node${node.productHierarchyNodeLevel}`
      }]
    });
  });

  for (let i = 0; i < 10; i++) {
    udaColDef.push({ headerName: `UDA${i+1}`, field: `uda${i+1}` });
    udaColDef.push({ headerName: `UDA${i+1}Type`, field: `uda${i+1}Type` });
  }

  const colDefs: any = [
    ...hierarchyMetadataColDef,
    ...nodeMetadataColDef,
    {
      headerName: 'Release Date',
      field: 'releaseDate',
    },
    {
      headerName: 'End Of Life',
      field: 'endofLifeDate',
    },
    {
      headerName: 'Cost',
      field: 'cost',

    },
    {
      headerName: 'Regular Retail Price',
      field: 'retailPrice',
    },
    {
      headerName: 'MSRP',
      field: 'msrp',
    },
    {
      headerName: 'Season',
      field: 'season',
    }
    ,
    {
      headerName: 'Source',
      field: 'source',
    },
    {
      headerName: 'Supplier',
      field: 'supplier',
    },
    {
      headerName: 'Active',
      field: 'active',
    },
    ...udaColDef
  ];

  return colDefs;
}
