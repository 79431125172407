import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-journey-view',
  templateUrl: './journey-view.component.html',
  styleUrls: ['./journey-view.component.scss']
})
export class JourneyViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,) { }

  ngOnInit() {
  }

}
