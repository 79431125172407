import { TreeviewItem } from 'ngx-treeview';

export const CreateProductHierarchyTree = (productData, selectedValues: any = []) => {
  let treeList = [];
  treeList = list_to_tree(productData);
  let tree = [];
  treeList.forEach((obj) => {
    let data = formTreeView(obj, selectedValues);
    tree.push(data);

  });
  return tree;
};

// create nth nested object of tree view items
const formTreeView = (obj, selectedValues) => {
  return new TreeviewItem({
    text: obj.productHierarchyValue,
    value: obj.productHierarchyId,
    collapsed: true,
    checked: selectedValues.includes(obj.productHierarchyId),
    children: obj.children && obj.children.length && obj.children.map((el) => {
      return formTreeView(el, selectedValues);
    })
  });
}

const list_to_tree = (list) => {
  let map = {};
  let node;
  const roots = [];

  for (let i = 0; i < list.length; i++) {
    map[list[i].productHierarchyId] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (let i = 0; i < list.length; i++) {
    node = list[i];
    if (node.productParentId && node.productParentId !== 0) {
      // if you have check that map[node.productParentId] exists..
      list[map[node.productParentId]].children.push(node);
      // }
    } else {
      roots.push(node);
    }
  }
  return roots;
}
