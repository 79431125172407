import { ColumnApi, GridApi } from 'ag-grid-enterprise';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { getFilterModel, getSortModel, gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { defaultColDef, getColDefs, getExportTemplateColDef } from './ag-grid/grid-options';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/services/configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { LocationSetupComponent } from './location-setup/location-setup.component';
import { ForecastLocationManagerService } from 'src/services/forecast-services/location-manager.service';
import { LocationHierarchyService } from 'src/services/setup-services/location-hierarchy.service';
import { LocationSetupEditRenderer } from './ag-grid/location-setup-edit-renderer.component';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';

@Component({
  selector: 'app-forecast-engine-location-manager',
  templateUrl: './forecast-engine-location-manager.component.html',
  styleUrls: ['./forecast-engine-location-manager.component.scss']
})
export class ForecastEngineLocationManagerComponent implements OnInit {
  rowModelType = 'serverSide';
  serverSideStoreType = 'partial';
  paginationPageSize = 20;
  cacheBlockSize = 20;
  totalRows = 0;
  public gridApi: GridApi;
  hiddenGridApi: GridApi;
  columnHiddenDefs: any = [];
  public colApi: ColumnApi;
  public defaultColDef = defaultColDef;
  public columnDefs = getColDefs();
  frameworkComponents = {
    locationSetupEditRenderer: LocationSetupEditRenderer,
  };
  gridData: any = [];
  selectedRows: any = [];
  nodeListValue: any = [];
  node2list: Observable<any[]>;
  node1list: Observable<any[]>;
  active: boolean;
  isGridLoaded: boolean = false;
  filterEventData: any = {};
  locationHierarchyMetadataList: any = [];
  locationHierarchyNodeMetadataList: any = [];
  public actionSubscription: Subscription;
  public locationSetupSubscription: Subscription;
  launchDate;
  closingDate;
  node1: string = '';
  node2: string = '';
  statusList = [
    { statusId: false, statusName: 'Inactive' },
    { statusId: true, statusName: 'Active' }
  ];
  locationHierarchyRowResponse: any = [];


  constructor(
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    private forecastLocationManagerService: ForecastLocationManagerService,
    public locationHierarchyService: LocationHierarchyService,

  ) { }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  ngOnInit() {
    this.getNodeList();
    this.listenSubscriptions();
    this.GetLocationMetadataList();
  }

  getNodeList() {
    this.forecastLocationManagerService.LocationManagerNodesGetList().subscribe(res => {
      this.nodeListValue = res;
    });
  }

  GetLocationMetadataList() {
    this.locationHierarchyService.GetLocationHierarchyListMetaDataList().subscribe(response => {
      this.locationHierarchyMetadataList = response.locationMetadataList;
      this.locationHierarchyService.locationHierarchyMetadataList = response.locationMetadataList;
      this.GetHierarchyNodeMetadataList();
    });
  }

  GetHierarchyNodeMetadataList() {
    this.locationHierarchyService.GetLocationHierarchyListNodeMetadataList().subscribe(response => {
      this.locationHierarchyNodeMetadataList = response.locationHierarchyNodeMetaList;
      this.locationHierarchyService.locationHierarchyNodeMetadataList = response.locationHierarchyNodeMetaList;
      this.columnDefs = getColDefs(this.locationHierarchyMetadataList, this.locationHierarchyNodeMetadataList);
      this.columnHiddenDefs = getExportTemplateColDef(this.locationHierarchyMetadataList, this.locationHierarchyNodeMetadataList);
      this.isGridLoaded = true;
    });
  }

  displayLocationFn(item): string {
    return item && (typeof item === 'object' && item.node2) ? item.node2 : item;
  }

  _filterLocationByKey(event) {
    let value = _.toLower(event.target.value);
    if (!value) {
      this.node2 = null;
    }
    const itemsList = _.filter(this.nodeListValue, item => { return _.toLower(item.node2).indexOf(value) > -1 })
    this.node2list = of(itemsList);
  }

  onLocationFocus(event) {
    this.node2list = of(this.nodeListValue.filter(ele => ele.node2 === event.target.value));
  }

  selectLocationManager = (item?) => {
    this.node2 = item.node2;
    this.applyFilterEvent();
  }

  displayLocationFn2(item): string {
    return item && (typeof item === 'object' && item.node1) ? item.node1 : item;
  }

  _filterLocationByKey2(event) {
    let value = _.toLower(event.target.value);
    if (!value) {
      this.node1 = null;
    }
    const itemsList = _.filter(this.nodeListValue, item => { return _.toLower(item.node1).indexOf(value) > -1 })
    this.node1list = of(itemsList);
  }

  onLocationFocus2(event) {
    this.node1list = of(this.nodeListValue.filter(ele => ele.node1 === event.target.value));
  }

  selectLocationManager2 = (item?) => {
    this.node1 = item.node1;
    this.applyFilterEvent();
  }


  locationHierarchyGetList() {
    this.forecastLocationManagerService.LocationHierarchyGetList().subscribe(locationList => {
      this.gridData = locationList;
      this.spinner.hide();
    });
  }

  listenSubscriptions() {
    this.actionSubscription = this.forecastLocationManagerService.getLocationGridActionCellSubject().subscribe(data => {
      if (data) {
        const locationDetailData = {
          isEdit: true,
          locationData: data.locationData,
          locationItemList: []
        }
        this.openLocationSetupDetail(locationDetailData);
      }
    });

    this.locationSetupSubscription = this.forecastLocationManagerService.getLocationSetupActionSubject().subscribe(data => {
      if (data) {
        this.createDataSource();
      }
    });
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.createDataSource();
  }

  clearValues() {
    this.node1 = '';
    this.active = null;
    this.node2 = '';
    this.launchDate = '';
    this.closingDate = '';
    this.applyFilterEvent();
  }

  applyFilterEvent() {
    const launchDateValue = this.launchDate ? moment(this.launchDate).format('YYYY-MM-DD') : '';
    const closingDateValue = this.closingDate ? moment(this.closingDate).format('YYYY-MM-DD') : '';
    this.filterEventData = {
      ...launchDateValue && { LaunchDate: launchDateValue },
      ...closingDateValue && { ClosingDate: closingDateValue },
      ...this.active !== null && { active: this.active },
      ...this.node1 && { Node1: this.node1 },
      ...this.node2 && { Node2: this.node2 },
    };
    this.createDataSource();
  }



  createDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          // 0 base index of page number
          active: (this.active) ? this.active : null,
          offSet: params.request.startRow,
          pageSize: this.paginationPageSize,
          ...this.filterEventData
        };

        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);
        this.spinner.show();
        this.forecastLocationManagerService.LocationHierarchyGetList(requestBody).pipe(
          tap(() => this.gridApi.hideOverlay())
        ).subscribe((response: any) => {
          const gridData = response.data;
          const totalRecord = _.get(response, 'totalRecord', 0);
          this.totalRows = totalRecord;
          this.totalRows = response.data.length;
          params.successCallback(gridData, response.data.length);
          this.deselectNode();
          response.data.length == 0 && this.gridApi.showNoRowsOverlay();
          this.gridApi && this.gridApi.setRowData(gridData);
          this.spinner.hide();
        });
      },
    };
    this.gridApi && this.gridApi.setServerSideDatasource(datasource);
  }


  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  exportLocationListGridData() {
    const sortModel = this.colApi.getColumnState();
    const filterModel = this.gridApi.getFilterModel();
    const requestBody: any = {
      // 0 base index of page number
      offSet: 0,
      pageSize: this.totalRows,
      ...this.filterEventData
    };

    requestBody.filterModel = getFilterModel(filterModel);
    requestBody.sortModel = getSortModel(sortModel);

    this.forecastLocationManagerService.LocationHierarchyGetList(requestBody).subscribe((response: any) => {
      this.gridData = response.data;
      setTimeout(() => {
        this.exportCSVLocationSetupGridData();
      }, 200);
    });
  }


  onHiddenGridReady(params) {
    this.hiddenGridApi = params.api;
  }

  onExportLocationTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Location-Template',
      colDefs: this.columnHiddenDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.hiddenGridApi.exportDataAsCsv(csvExportParams);
  }



  exportCSVLocationSetupGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Location Setup',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  importDataByTemplate() {
    (document.getElementById('upload-location-setup-data') as any).value = '';
    document.getElementById('upload-location-setup-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();

    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;

      formDataReader.append('file', files);
      this.uploadImportedLocationCsvFile(formDataReader);
    }
  }

  uploadImportedLocationCsvFile(file) {
    this.forecastLocationManagerService.ImportLocationManagerTemplate(file).subscribe(
      (res: any) => {
        if (res && res.length) {
          const successMessages = [];
          const errorMessages = [];
          const warningMessages = [];
          const infoMessages = [];
          res.forEach(element => {

            switch (element.messageType) {
              case 'Success':
                successMessages.push(element.messageText);
                break;
              case 'Warning':
                warningMessages.push(element.messageText);
                break;
              case 'Info':
                infoMessages.push(element.messageText);
                break;
              case 'Error':
                errorMessages.push(element.messageText);
                break;
              default:
                break;
            }
          });

          if (errorMessages.length)
            this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
          if (successMessages.length)
            this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
          if (warningMessages.length)
            this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
          if (infoMessages.length)
            this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);

          this.createDataSource();
        } else {
          this.toastr.warning('Warning', 'Location Data Import Failed.');
        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Location Data Import Failed.');
      });
  }


  openLocationSetupDetail(data = {}): void {
    const locationSetupComponent = {
      menuId: '453',
      name: 'Location setup ',
      selector: 'app-location-setup',
      displayName: 'Location Setup',
      module: 'ForecastEnginModule',
      data: data,
      component: LocationSetupComponent
    }
    this.configurationService.menuRendererSubject.next(locationSetupComponent);
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
    this.locationSetupSubscription.unsubscribe();
  }
}
