import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ColumnApi, ColumnState, GridApi, GridOptions } from 'ag-grid-community';
import { ConfigurationService } from 'src/services/configuration.service';
import { defaultColDef, getJourneyLogColDefs } from '../ag-grid/grid-options';
import { JourneyLogActionCellRenderer } from '../ag-grid/journey-log-action-cell-renderer.component';
import { PersistentContextService } from 'src/services/persistent-context-services/persistent-context.service';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { JOURNEY_ACTIVITY_LIST } from 'src/common/keys';

@Component({
  selector: 'journey-logs',
  templateUrl: './journey-logs.component.html',
  styleUrls: ['./journey-logs.component.scss']
})

export class JourneyLogsComponent implements OnInit, OnDestroy {

  paginationPageSize = 50;
  cacheBlockSize = 50;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  public defaultColDef = defaultColDef;
  public columnDefs = getJourneyLogColDefs({}, this);
  gridData: any = [];
  frameworkComponents = {
    journeyLogActionCellRenderer: JourneyLogActionCellRenderer
  };
  public gridColumnState: ColumnState[] = [];
  screenPreference: any;
  public actionSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private configService: ConfigurationService,
    private spinner: NgxSpinnerService,
    private persistentContextService: PersistentContextService,
    private userPreferenceService: UserPreferenceService,
  ) { }

  ngOnInit(): void {
    this.fetchUserActivityLogs();
    this.actionSubscription = this.persistentContextService.getJourneyLogGridActionSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'visibility': {
        this.openJourney(actionObject.data);
        break;
      }
    }
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(JOURNEY_ACTIVITY_LIST, JOURNEY_ACTIVITY_LIST);
    this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
    this.colApi.applyColumnState({ state: this.gridColumnState });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.getUserSecreenPrefereence();
  }

  onGridStateChange(e) {
    this.gridColumnState = this.colApi.getColumnState();
    this.screenPreference.columnState = this.gridColumnState;
    this.updateScreenPreference();
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  fetchUserActivityLogs() {
    this.spinner.show();
    this.configService
      .GetJourneyUserActivityLog()
      .subscribe((res: any[]) => {
        this.spinner.hide();
        this.gridData = res;
      });
  }

  openJourney(element: any) {
    element = element.journey;
    if (element?.metaData) {
      const data = JSON.parse(element.metaData);
      data.isPowerBiReport = true;
      data.createdBy = element.createdBy;
      data.createdOn = element.createdOn;
      data.journeyId = element.journeyId;
      data.journeyName = element.journeyName;
      data.originalGroupId = element.originalGroupId;
      data.originalReportId = element.originalReportId;
      data.originalReportName = element.originalReportName;
      this.configService.viewJourney.emit(data);
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
  }

}
