import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PersistentContextService } from 'src/services/persistent-context-services/persistent-context.service';

@Component({
  selector: 'my-journey-action-cell-renderer',
  template: `<div class="grid-action-cell" *ngIf="!params.node.rowPinned">
        <span class="material-icons" (click)="actionClickHandler('delete')">delete</span>
        <span class="material-icons" (click)="actionClickHandler('edit')">edit</span>
        <span class="material-icons" (click)="actionClickHandler('visibility')">visibility</span>
        <span class="material-icons" (click)="actionClickHandler('share')">share</span>
    </div>`,
})
export class MyJourneyActionCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private persistentContextService: PersistentContextService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  actionClickHandler(action) {
    this.persistentContextService.setMyJourneyGridActionSubject({ action, data: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
