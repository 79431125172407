<div class="journey-modal-header">
  <h2>Share Journey</h2>
  <mat-icon class="cursor-pointer" (click)="close()">cancel</mat-icon>
</div>
<div class="journey-modal-header form-field w-100">
  <form class="example-form w-100 d-flex mt-2">
    <mat-form-field appearance="outline" class="example-chip-list mr-1">
      <mat-label>Username</mat-label>
      <mat-chip-list #chipList aria-label="User selection">
        <mat-chip *ngFor="let user of selectedUsers" [selectable]="selectable" [removable]="removable" (removed)="remove(user)">
          {{user.email}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Add User..." #userInput [formControl]="userCtrl" [matAutocomplete]="auto"
          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{user.email}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 33% !important;">
      <mat-select [formControl]="selectedPrivilege">
        <mat-option *ngFor="let item of privilegeOptions" [value]="item.id">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<section class="example-container share-journey-table" tabindex="0">
  <table mat-table [dataSource]="dataSource" class="w-100" *ngIf="isTableVisible">
    <!-- Email Column -->
    <ng-container matColumnDef="email" sticky>
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <!-- Privilege Column -->
    <ng-container matColumnDef="privilege" sticky>
      <th mat-header-cell *matHeaderCellDef>User Permission</th>
      <td mat-cell *matCellDef="let element">{{ element.privilege }}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action" stickyEnd>
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon style="cursor: pointer;" *ngIf="isOwner(element)" (click)="delete(element)">delete</mat-icon>
        <mat-icon style="cursor: pointer;" *ngIf="isOwner(element)" (click)="edit(element)">edit</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>

<div class="w-100 d-flex justify-content-end">
  <button mat-stroked-button color="accent" (click)="close()">Cancel</button>
  <button mat-flat-button="" color="accent"
    class="mat-focus-indicator ml-2 mat-flat-button mat-button-base mat-accent float-right" (click)="save()">
    <span class="mat-button-wrapper">Share</span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
      class="mat-button-focus-overlay"></span>
  </button>
</div>