<div>
  <div class="all-show-grid journey-grid my-journey-ag-grid">
    <ag-grid-angular #foreCastManagerGrid id="seasonality-data-grid" class="ag-theme-material forecast-manager-grid"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData" [defaultColDef]="defaultColDef"
      [suppressRowClickSelection]="true" [frameworkComponents]="frameworkComponents" [animateRows]="true"
      [pagination]="true" [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
      (gridReady)="onGridReady($event)" (sortChanged)="onGridStateChange($event)"
      (columnVisible)="onGridStateChange($event)" (columnMoved)="onGridStateChange($event)"
      (columnPinned)="onGridStateChange($event)">
    </ag-grid-angular>
  </div>
</div>