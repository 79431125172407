<div class="container-fluid" style="margin-top: 15px;">
    <!-- TABS ~ Start -->
    <mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged()">
        <mat-tab label="Settings">
            <cluster-settings-tab [setSettingsExpansionSettings]="setSettingsExpansionSettings" (runClusterEvent)="runClusterHandler($event)"></cluster-settings-tab>
        </mat-tab>
        <mat-tab label="Output" *ngIf="outputTabData">
            <cluster-output-tab [versionNo]="versionNo" [resizeGraphSubject]="resizeGraphSubject" [sampleData]="outputTabData" (saveRunOutput)="outputTabRunBtnHandler($event)"></cluster-output-tab>
        </mat-tab>
        <mat-tab *ngFor="let tab of runTabs; let i=index" label="{{tab.tabName ? tab.tabName : 'Run '+ (i+1)}}">
            <ng-template mat-tab-label>
                <div class="tab-label-div">
                    {{tab.tabName ? tab.tabName : 'Run '+ (i+1)}}
                    <mat-icon style="right: 0;" matTooltip="Edit Name" color="primary" (click)="editTabName(tab, i, $event)">edit</mat-icon>
                    <mat-icon matTooltip="Remove Run" color="accent" (click)="removeTab(i, $event)">highlight_off</mat-icon>
                </div>
            </ng-template>
            <run-tab [versionNo]="versionNo" [dataObj]="tab" [resizeGraphSubject]="resizeGraphSubject"></run-tab>
        </mat-tab>
        <mat-tab *ngIf="runTabs.length > 1" label="Comparison">
            <comparison-tab [versionNo]="versionNo" [runs]="runTabs.length" [charts]="runTabs" [resizeGraphSubject]="resizeGraphSubject"></comparison-tab>
        </mat-tab>
    </mat-tab-group>
    <!-- TABS ~ End -->
</div>
 