import { CombinationSetupComponent } from './combination-setup/combination-setup.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, Subscription } from 'rxjs';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { getFilterModel, getSortModel, gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { ConfigurationService } from 'src/services/configuration.service';
import { ForecastItemManagerService } from 'src/services/forecast-services/item-manager.service';
import { ForecastLocationManagerService } from 'src/services/forecast-services/location-manager.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { LocationHierarchyService } from 'src/services/setup-services/location-hierarchy.service';
import { ProductHierarchyService } from 'src/services/setup-services/product-hierarchy.service';
import { defaultColDef, getColDefs, getExportTemplateColDefs } from './ag-grid/grid-option';
import { ColumnApi, GridApi, GridOptions, ServerSideStoreType } from 'ag-grid-enterprise';

import * as _ from 'lodash'
import { tap } from 'rxjs/operators';
import moment from 'moment';
import { CombinationManagerService } from 'src/services/forecast-services/combination-manager.service';
import { T } from '@angular/cdk/keycodes';
import { CombinationEditRenderer } from './ag-grid/combination-edit-renderer.component';

@Component({
  selector: 'app-forecast-engine-combination-manager',
  templateUrl: './forecast-engine-combination-manager.component.html',
  styleUrls: ['./forecast-engine-combination-manager.component.scss']
})
export class ForecastEngineCombinationManagerComponent implements OnInit {
  paginationPageSize = 1000;
  rowModelType = 'serverSide';
  rowSelection = 'multiple';
  serverSideStoreType = 'partial';
  cacheBlockSize = 1000;
  public gridOptions: GridOptions;
  public gridApi: GridApi;
  hiddenGridApi: GridApi;
  columnHiddenDefs: any = [];
  public colApi: ColumnApi;
  public defaultColDef = defaultColDef;
  public columnDefs = getColDefs();
  frameworkComponents = {
    combinationEditRenderer: CombinationEditRenderer,
  };
  gridData: any = [];
  selectedRows: any = [];
  nodeListValue: any = [];
  locationNodeListValue: any = [];
  node2list: Observable<any[]>;
  node1list: Observable<any[]>;
  relationship: boolean;
  isGridLoaded: boolean = false;
  filterEventData: any = {};
  locationHierarchyMetadataList: any = [];
  locationHierarchyNodeMetadataList: any = [];
  productHierarchyMetadataList: any = [];
  productHierarchyNodeMetadataList: any = [];
  public actionSubscription: Subscription;
  public combinationSetupSubscription: Subscription;
  totalRows: any;
  launchDate;
  closingDate;
  itemNode1: string = '';
  itemNode2: string = '';
  locationNode2: string = '';
  statusList = [
    { statusId: null, statusName: 'All' },
    { statusId: false, statusName: 'Inactive' },
    { statusId: true, statusName: 'Active' }
  ];
  locationHierarchyRowResponse: any = [];
  releaseFromDate;
  releaseToDate;
  filterEvent: any = {};
  storeCodeList: any = [];
  account;
  accountList: any = [];

  constructor(
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public productHierarchyService: ProductHierarchyService,
    private itemManagerService: ForecastItemManagerService,
    private combinationManagerService: CombinationManagerService,
    public locationHierarchyService: LocationHierarchyService,
    public dialogRef: MatDialogRef<CombinationSetupComponent>,
    private forecastLocationManagerService: ForecastLocationManagerService,


  ) { }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  ngOnInit() {
    this.listenSubscriptions();
    this.GetProductMetadataList();
    this.getNodeList();
  }

  listenSubscriptions() {
    this.combinationSetupSubscription = this.combinationManagerService.getCombinationGridActionCellSubject().subscribe(data => {
      if (data) {
        this.createDataSource();
      }
    });
  }

  getNodeList() {
    this.productHierarchyService.GetProductHierarchyNodeMetadataList().subscribe(res => {
      this.nodeListValue = res;
    });
    this.forecastLocationManagerService.LocationManagerNodesGetList().subscribe(res => {
      this.locationNodeListValue = res;
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.createDataSource();
  }
  onHiddenGridReady(params) {
    this.hiddenGridApi = params.api;
  }
  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  GetProductMetadataList() {
    this.productHierarchyService.GetProductHierarchyList().subscribe(response => {
      this.productHierarchyMetadataList = response.productMetadataList;
      this.productHierarchyService.productHierarchyMetadataList = response.productMetadataList;
      this.GetProductHierarchyNodeMetadataList();
    });
  }

  addRelationship(reltionship: boolean){
    if(this.selectedRows.length){
      this.spinner.show();
      this.selectedRows.forEach(row => {
        row.relationship= reltionship;
      });
      console.log(this.selectedRows);
      this.combinationManagerService.CombinationManagerAddRelationShip(  this.selectedRows ).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Success', `Relationship created successfully.`);
          this.clearSelectedRows();
          this.createDataSource();

        };
      }, err => {
        this.toastr.error('Error', `Some errors occur.`);
        this.spinner.hide();
      });
    }
  }

  GetProductHierarchyNodeMetadataList() {
    this.productHierarchyService.GetHierarchyNodeMetadataList().subscribe(response => {
      this.productHierarchyNodeMetadataList = response.productHierarchyNodeMetaList;
      this.productHierarchyService.productHierarchyNodeMetadataList = response.productHierarchyNodeMetaList;
      this.GetLocationMetadataList();
    });
  }
  GetLocationMetadataList() {
    this.locationHierarchyService.GetLocationHierarchyListMetaDataList().subscribe(response => {
      this.locationHierarchyMetadataList = response.locationMetadataList;
      this.locationHierarchyService.locationHierarchyMetadataList = response.locationMetadataList;
      this.GetLocationHierarchyNodeMetadataList();
    });
  }

  GetLocationHierarchyNodeMetadataList() {
    this.locationHierarchyService.GetLocationHierarchyListNodeMetadataList().subscribe(response => {
      this.locationHierarchyNodeMetadataList = response.locationHierarchyNodeMetaList;
      this.locationHierarchyService.locationHierarchyNodeMetadataList = response.locationHierarchyNodeMetaList;
      this.columnDefs = getColDefs(this.locationHierarchyMetadataList, this.locationHierarchyNodeMetadataList,
        this.productHierarchyMetadataList, this.productHierarchyNodeMetadataList
      );
      this.columnHiddenDefs = getExportTemplateColDefs(this.locationHierarchyMetadataList, this.locationHierarchyNodeMetadataList,
        this.productHierarchyMetadataList, this.productHierarchyNodeMetadataList
      );
      this.isGridLoaded = true;
    });
  }


  applyFilterEvent() {
    const releaseFromDateValue = this.releaseFromDate ? moment(this.releaseFromDate).format('YYYY-MM-DD') : '';
    const releaseToDateValue = this.releaseToDate ? moment(this.releaseToDate).format('YYYY-MM-DD') : '';
    this.filterEventData = {
      ...releaseFromDateValue && { ReleaseFromDate: releaseFromDateValue },
      ...releaseToDateValue && { ReleaseToDate: releaseToDateValue },
      ...this.relationship !== null && { relationship: this.relationship },
      ...this.locationNode2 && { locationNode2: this.locationNode2 },
      ...this.itemNode1 && { itemNode1: this.itemNode1 },
      ...this.itemNode2 && { itemNode2: this.itemNode2 },
    };
    this.createDataSource();
  }

  clearValues() {
    this.releaseFromDate = '';
    this.releaseToDate = '';
    this.locationNode2 = '';
    this.itemNode1 = '';
    this.itemNode2 = '';
    this.relationship = null;
    this.applyFilterEvent();
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  openCombinationSetup(data = {}): void {
    this.dialogRef = this.dialog.open(CombinationSetupComponent, {
      width: '950px',
      // height:'600px',
      disableClose: true,
      data: data
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createDataSource();
      }
    });
  }


  exportCombinationListGridData() {
    const sortModel = this.colApi.getColumnState();
    const filterModel = this.gridApi.getFilterModel();
    const requestBody: any = {
      // 0 base index of page number
      offSet: 0,
      pageSize: this.totalRows,
      ...this.filterEventData
    };

    requestBody.filterModel = getFilterModel(filterModel);
    requestBody.sortModel = getSortModel(sortModel);

    this.combinationManagerService.CombinationMangerGetList(requestBody).subscribe((response: any) => {
      this.gridData = response.data;
      setTimeout(() => {
        this.exportCSVCombinationSetupGridData();
      }, 200);
    });
  }

  exportCSVCombinationSetupGridData() {
    const csvExportParam = gridDataExportParams({
      fileName: 'Combination Manager',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParam);
  }

  onExportCombinationTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Combination-Template',
      colDefs: this.columnHiddenDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.hiddenGridApi.exportDataAsCsv(csvExportParams);
  }

  importDataByTemplate() {
    (document.getElementById('upload-combination-manager-data') as any).value = '';
    document.getElementById('upload-combination-manager-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();

    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;

      formDataReader.append('file', files);
      this.uploadImportedCombinationCsvFile(formDataReader);
    }
  }
  createDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          // 0 base index of page number
          relationship: (this.relationship) ? this.relationship : null,
          offSet: params.request.startRow,
          pageSize: this.paginationPageSize,
          ...this.filterEventData
        };

        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);
        this.spinner.show();
        this.combinationManagerService.CombinationMangerGetList(requestBody).pipe(
          tap(() => this.gridApi.hideOverlay())
        ).subscribe((response: any) => {
          this.spinner.hide();
          const gridData = response.data;
          const totalRecord = _.get(response, 'totalRecord', 0);
          this.totalRows = totalRecord;
          this.totalRows = response.data.length;
          params.successCallback(gridData, response.data.length);
          this.deselectNode();
          response.data.length == 0 && this.gridApi.showNoRowsOverlay();
          this.gridApi && this.gridApi.setRowData(gridData);
          this.spinner.hide();
        });
      },
    };
    this.gridApi && this.gridApi.setServerSideDatasource(datasource);
  }


  uploadImportedCombinationCsvFile(file) {
    this.combinationManagerService.ImportCombinationManagerTemplate(file).subscribe(
      (res: any) => {
        if (res && res.length) {
          const successMessages = [];
          const errorMessages = [];
          const warningMessages = [];
          const infoMessages = [];
          res.forEach(element => {

            switch (element.messageType) {
              case 'Success':
                successMessages.push(element.messageText);
                break;
              case 'Warning':
                warningMessages.push(element.messageText);
                break;
              case 'Info':
                infoMessages.push(element.messageText);
                break;
              case 'Error':
                errorMessages.push(element.messageText);
                break;
              default:
                break;
            }
          });

          if (errorMessages.length)
            this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
          if (successMessages.length)
            this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
          if (warningMessages.length)
            this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
          if (infoMessages.length)
            this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);

          this.createDataSource();
        } else {
          this.toastr.warning('Warning', 'Combination Data Import Failed.');
        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Combination Data Import Failed.');
      });
  }


  displayCombinationFn(item): string {
    return item && (typeof item === 'object' && item.node2) ? item.node2 : item;
  }

  _filterCombinationByKey(event) {
    let value = _.toLower(event.target.value);
    if (!value) {
      this.itemNode2 = null;
    }
    const itemsList = _.filter(this.nodeListValue, item => { return _.toLower(item.node2).indexOf(value) > -1 })
    this.node2list = of(itemsList);
  }

  onCombinationFocus(event) {
    this.node2list = of(this.nodeListValue.filter(ele => ele.node2 === event.target.value));
  }

  selectCombinationManger = (item?) => {
    this.itemNode2 = item.node2;
    this.applyFilterEvent();
  }

  displayCombinationFn2(item): string {
    return item && (typeof item === 'object' && item.node1) ? item.node1 : item;
  }

  _filterCombinationByKey2(event) {
    let value = _.toLower(event.target.value);
    if (!value) {
      this.itemNode1 = null;
    }
    const itemsList = _.filter(this.nodeListValue, item => { return _.toLower(item.node1).indexOf(value) > -1 })
    this.node1list = of(itemsList);
  }

  onCombinationFocus2(event) {
    this.node1list = of(this.nodeListValue.filter(ele => ele.node1 === event.target.value));
  }

  selectCombinationManger2 = (item?) => {
    this.itemNode1 = item.node1;
    this.applyFilterEvent();
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
    this.combinationSetupSubscription.unsubscribe();
  }

}
