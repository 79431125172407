import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SharedModule } from 'src/shared/shared.module';
import { NavComponent } from './features/nav/nav.component';
import { MainComponent } from './features/main/main.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { Error404Component } from './features/404/error404.component';
import { LayoutRoutes } from './layout.routing';
import { AlgoBotComponent } from './features/algo-bot/algo-bot.component';
import { ActivityComponent } from './features/activity/activity.component';
import { AnomolyDetectorFormComponent } from './features/anomoly-detector-form/anomoly-detector-form.component';
import { InsightListingComponent } from './features/app-insight/insight-listing/insight-listing.component';
import { InsightFormComponent } from './features/app-insight/insight-listing/insight-form/insight-form.component';
import { InsightListingV2Component } from './features/app-insight-v2/insight-listing-v2/insight-listing-v2.component';
import { InsightFormV2Component } from './features/app-insight-v2/insight-listing-v2/insight-form-v2/insight-form-v2.component';
import { NotificationConfigurationComponent } from './features/notification-configuration/notification-configuration.component';
import { SuggestAdminComponent } from './features/suggest-admin/suggest-admin.component';
import { UserInterfaceComponent } from './features/user-interface/user-interface.component';
import { AlgoMenuComponent } from './features/algo-menu/algo-menu.component';
import { Pages } from 'src/common/pages';
import { createCustomElement } from '@angular/elements';
import { ConfigurationService } from 'src/services/configuration.service';
import { ChannelsMenuComponent } from './features/channels-menu/channels-menu.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LayoutRoutes,
  ],
  declarations: [
    LayoutComponent,
    NavComponent,
    MainComponent,
    DashboardComponent,
    Error404Component,
    AlgoBotComponent,
    ActivityComponent,
    AnomolyDetectorFormComponent,
    InsightListingComponent,
    InsightFormComponent,
    InsightFormV2Component,
    InsightListingV2Component,
    NotificationConfigurationComponent,
    SuggestAdminComponent,
    UserInterfaceComponent,
    AlgoMenuComponent,
    ChannelsMenuComponent
  ],
  entryComponents: [
    AlgoBotComponent,
    ActivityComponent,
    AnomolyDetectorFormComponent,
    InsightListingComponent,
    InsightFormComponent,
    InsightFormV2Component,
    InsightListingV2Component,
    NotificationConfigurationComponent,
    SuggestAdminComponent,
    UserInterfaceComponent,
    AlgoMenuComponent
  ]
})
export class LayoutModule {
  constructor(private injector: Injector, public state: ConfigurationService) {
    if (!this.state.isLayoutLoaded) {
      this.state.isLayoutLoaded = true;
      const appPagesList = Pages.filter(res => res.module === 'app');
      appPagesList.forEach(page => {
        const el = createCustomElement(page.component, { injector });
        customElements.define(page.selector, el);
      });
    }
  }
}
