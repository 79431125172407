<div class="journey-modal-header">
  <h2>Share Journey</h2>
  <mat-icon class="cursor-pointer" (click)="close()">cancel</mat-icon>
</div>
<div class="journey-modal-header form-field w-100">
  <form class="example-form w-100 d-flex mt-2">
    <mat-form-field appearance="outline" class="example-chip-list mr-1">
      <mat-label>Username</mat-label>
      <mat-chip-list #emailChipList>
        <mat-chip [selectable]="selectable" [removable]="removable">
          {{user.email}}
        </mat-chip>
        <input readonly #userInput [formControl]="userCtrl" [matChipInputFor]="emailChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 33% !important;">
      <mat-select [formControl]="selectedPrivilege">
        <mat-option *ngFor="let item of privilegeOptions" [value]="item.id">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div class="w-100 d-flex justify-content-end">
  <button mat-stroked-button color="accent" (click)="close()">Cancel</button>
  <button mat-flat-button color="accent" class="ml-2" (click)="save()">Save</button>
</div>