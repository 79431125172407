import { Component, OnInit, ViewChild, Inject, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldsSettingsModel, NodeCheckEventArgs, TreeView, DragAndDropEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CATEGORY_COL_DEFS } from 'src/common/algo-column-definitions';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { USER_ID } from 'src/common/keys';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import * as _ from 'lodash';

@Component({
  selector: 'app-algo-menu',
  templateUrl: './algo-menu.component.html',
  styleUrls: ['./algo-menu.component.scss']
})
export class AlgoMenuComponent implements OnInit {
  @ViewChild('tree') public tree: TreeView;

  public registerForm: FormGroup;
  public submitted = false;
  public isEdit = false;
  public columnDefs = CATEGORY_COL_DEFS;
  private gridApi: any;
  public environment;
  status = false;
  public field = {};
  public menuList = [];
  public gridOptions: any;
  public groupDefaultExpanded;
  public getDataPath;
  public autoGroupColumnDef;
  public allowEditing: boolean = true;
  public menu: any;
  public screenList = [];
  searchCtrl = new FormControl();
  filteredMenus: Observable<any[]>;
  public roleList: any = [];
  updatedParentMenuRoles: any = [];
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public userService: UsersService,
    public dialogAddRef: MatDialogRef<any>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.environment = environment;
  }

  ngOnInit() {
    this.getDataPath = (data) => {
      return data.parentId;
    };
    this.getRoleList();
    this.getScreenList();
    this.populateList();
    this.initializeForm();
    this.userService.listUpdate$.subscribe(res => {
      if (res) {
        this.getRoleList();
      }
    });
  }

  public getRoleList = () => {
    this.userService.GetUserRolesAll().subscribe(res => {
      this.roleList = (res as any).data;
    });
  }

  public _filterScreen(event) {
    let value = event.target.value;
    const filterValue: string = value.toLowerCase();
    const pageList = this.screenList.filter(page => {
      const list = filterValue.toLocaleLowerCase().split(' ');
      return (page.name.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) > -1);
    });
    this.filteredMenus = of(pageList);
  }

  applyRoleFilter(event) {
    if (event.value === 'clear') {
      this.populateList();
    } else {
      this.populateList({ roleIds: event.value });
    }
  }

  public populateList = (params: any = {}) => {
    this.spinner.show();
    this.configurationService.getlistbyadmin(params).subscribe((res: any) => {
      this.menuList = res;
      this.menuList = this.menuList.sort((a, b) => a.orderNumber - b.orderNumber);
      this.menuList.forEach(menu => {
        menu.parentId = menu.parentId ? menu.parentId : null;
        if (this.menuList.find(result => menu.menuId === result.parentId)) {
          menu.hasChild = true;
        }
      });
      this.field = { dataSource: this.menuList, id: 'menuId', parentID: 'parentId', text: 'menuName', hasChildren: 'hasChild' };
      this.spinner.hide();
    });
  }
  public getScreenList = () => {
    this.configurationService.ScreenGetlist({}).subscribe(screeList => {
      this.screenList = (screeList || []);
      this.filteredMenus = of(this.screenList);
    });
  }
  public editing(args: NodeCheckEventArgs) {
    // if (args.node.parentNode.parentNode.nodeName !== 'LI') {
    //   args.cancel = true;
    // }
  }
  public removeNode = (nodeData: any) => {
    let list = (this.field as any).dataSource;
    const model = {
      menuId: nodeData.menuId,
      active: false
    };
    this.configurationService.activateMenu(model).subscribe(flag => {
      if (flag) {
        list = (this.field as any).dataSource.filter(res => res.menuId !== nodeData.menuId);
        this.field = { dataSource: list, id: 'menuId', parentID: 'parentId', text: 'menuName', hasChildren: 'hasChild' };
        this.tree.expandAll();
      }
    });
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group({
      menuId: [0],
      screenId: [0, Validators.required],
      orignalName: [''],
      displayName: [''],
      parentId: [0],
      orderNumber: [0],
      menuName: [''],
      description: [''],
      name: [''],
      germanName: [''],
      chineseName: [''],
      content: [''],
      active: true,
      currentUserId: [this.storage.get(USER_ID)],
      roleIds: [''],
      isGlobal: [false]
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      Object.keys(this.registerForm.controls).forEach(key => {
        if (dataToPopulate[key]) {
          this.registerForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }
  onNodeEdited($event) {
    const nodeData = $event.nodeData;
    const newCategory = {
      categoryId: nodeData.id,
      categoryName: $event.newText,
      parentId: nodeData.parentID,
      active: true
    };
    this.configurationService.updateCategory(newCategory).subscribe(res => {
      if (res) {
        this.toastr.success('Success', 'Category Updated Successfully');
      }
    });
  }
  Cancel_Click() {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  getRolesByMenuId(nodeData) {
    this.configurationService.GetRolesByMenuId(nodeData.menuId).subscribe(roleList => {
      if (roleList && roleList.length) {
        const roleIds = roleList.map(a => a.roleId);
        nodeData.roleIds = roleIds;
        this.populateForm({ data: nodeData });
      } else {
        this.populateForm({ data: nodeData });
      }
    });
  }

  openCategoryDialog(nodeData: any, templateRef: TemplateRef<any>, isEdit: boolean) {
    if (isEdit) {
      this.isEdit = true;
      this.getRolesByMenuId(nodeData);
    } else {
      this.isEdit = false;
      this.initializeForm();
    }
    // tslint:disable-next-line: no-use-before-declare
    this.dialogAddRef = this.dialog.open(templateRef, {
      width: '800px',
      data: nodeData
    });

    this.dialogAddRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatedParentMenuRoles = [];
        this.updatedParentMenuRole(result);
        this.updateParentMenuRole();
        result.roleIds = result.roleIds ? result.roleIds.join(',') : '';
        result.orignalName = _.get(result, 'orignalName.name', result.orignalName);
        if (result && result.parentId) {
          if (this.isEdit) {
            this.updateMenu(result);
          } else {
            this.addNewMenu(result);
          }
        } else {
          if (this.isEdit) {
            this.updatedParentMenu(result);
          } else {
            this.addParentMenu(result);
          }
        }
      }
    });
  }

  updateParentMenuRole() {
    this.updatedParentMenuRoles.forEach(menu => {
      menu.roleIds = menu.roleIds ? menu.roleIds.join(',') : '';
      this.updateMenu(menu);
    });
  }

  updatedParentMenuRole(menu: any) {
    if (menu.parentId) {
      const parentMenu = _.find(this.menuList, a => a.menuId == menu.parentId);
      const roleIds = this.registerForm.get('roleIds').value || [];
      parentMenu.roleIds = parentMenu.roleIds ? (Array.isArray(parentMenu.roleIds) ? parentMenu.roleIds : parentMenu.roleIds.split(',').map(a => Number(a))) : [];
      parentMenu.roleIds = _.uniq([...parentMenu.roleIds, ...roleIds]);
      this.updatedParentMenuRoles.push(Object.assign({}, parentMenu));
      if (parentMenu.parentId) {
        return this.updatedParentMenuRole(parentMenu);
      }
      return parentMenu;
    }
  }

  openConfirmationDialog(nodeData: any) {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { ...nodeData, headerName: nodeData.categoryName }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeNode(result);
      }
    });
  }
  public addNewMenu = (result: any) => {
    if (result) {
      let screen = this.screenList.find(row => row.screenId === result.screenId);
      const model = {
        ...screen,
        ...result,
      };
      this.configurationService.addMenu(model).subscribe(res => {
        if (res) {
          result.menuId = res;
          screen = this.screenList.find(row => row.screenId === result.screenId);
          result.name = screen ? screen.name : result.displayName;
          const list = (this.field as any).dataSource;
          list.forEach(element => {
            if (element.menuId === result.parentId) {
              element.hasChild = true;
            }
          });
          list.push(result);
          this.field = { dataSource: list, id: 'menuId', parentID: 'parentId', text: 'menuName', hasChildren: 'hasChild' };
          this.tree.refresh();
          this.configurationService.menuListSubject.next(true);
          this.submitted = false;
        }
      });
    }
  }
  public updateMenu = (result: any) => {
    if (result) {
      let screen = this.screenList.find(row => row.screenId === result.screenId);
      const model = {
        ...screen,
        ...result,
      };
      this.configurationService.updateMenu(model).subscribe(res => {
        if (res) {
          const list = (this.field as any).dataSource;
          screen = this.screenList.find(row => row.screenId === result.screenId);
          result.name = screen ? screen.name : result.displayName;
          list.forEach(element => {
            if (element.menuId === model.parentId) {
              element.hasChild = true;
            }
          });
          const index = list.findIndex(row => row.menuId === model.menuId);
          if (index > -1) {
            list[index] = model;
            this.field = { dataSource: list, id: 'menuId', parentID: 'parentId', text: 'menuName', hasChildren: 'hasChild' };
            this.tree.refresh();
            this.configurationService.menuListSubject.next(true);
          }
          // this.populateList();
          this.submitted = false;
          this.isEdit = false;
        }
      });
    }
  }
  public addParentMenu = (result: any) => {
    if (result) {
      const screen = this.screenList.find(row => row.screenId === result.screenId);
      const model = {
        ...screen,
        ...result,
      };
      this.spinner.show();
      this.configurationService.addMenu(model).subscribe(res => {
        if (res) {
          this.submitted = false;
          this.populateList();
          this.spinner.hide();
          this.configurationService.menuListSubject.next(true);
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  public updatedParentMenu = (result: any) => {
    if (result) {
      const screen = this.screenList.find(row => row.screenId === result.screenId);
      const model = {
        ...screen,
        ...result,
      };
      this.configurationService.updateMenu(model).subscribe(res => {
        if (res) {
          this.submitted = false;
          this.isEdit = false;
          this.populateList();
          this.configurationService.menuListSubject.next(model);
        }
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(data: any) {
    this.submitted = true;
    if (this.registerForm.valid) {
      if (this.isEdit) {
        this.dialogAddRef.close({ menuId: 0, ...this.registerForm.value });
      } else {
        this.dialogAddRef.close({ menuId: 0, ...this.registerForm.value, parentId: data.menuId });
      }
    }
  }
  public removeSpaces = (menuName: string) => {
    if (menuName) {
      return menuName.trim();
    }
  }
  clickEvent(id) {
    // this.status = !this.status;
    const shadesEl = document.getElementById(id);
    if (shadesEl.classList.contains('menu-show')) {
      shadesEl.classList.remove('menu-show');
    } else {
      shadesEl.classList.add('menu-show');
    }
  }
  syncPowerBIReports() {
    this.spinner.show();
    this.configurationService.syncPoweBIReports().subscribe(res => {
      this.getScreenList();
      this.spinner.hide();
      this.toastr.success('Success', 'Reports Synced Successfully');
    }, error => {
      this.spinner.hide();
      this.toastr.error('error', 'some error occured');
    });
  }
  public nodeDrag(args: DragAndDropEventArgs): void {
    if (args.droppedNode != null && args.droppedNode.getElementsByClassName('folder') && args.droppedNode.getElementsByClassName('folder').length === 0) {
      // args.dropIndicator = 'e-no-drop';
    }
  }

  public dragStop(args: DragAndDropEventArgs): void {
    if (args.draggedNodeData && args.droppedNodeData && args.draggedNodeData.parentID != args.droppedNodeData.parentID) {
      args.cancel = true;
    } else if (args.draggedNodeData && args.droppedNodeData) {
      const draggedOrder = this.menuList.find(row => row.menuId == args.draggedNodeData.id).orderNumber;
      const droppedOrder = this.menuList.find(row => row.menuId == args.droppedNodeData.id).orderNumber;
      let orderedList = this.menuList.filter(row => row.parentId == args.draggedNodeData.parentID
        && ((row.orderNumber >= draggedOrder && row.orderNumber <= droppedOrder) || (row.orderNumber >= droppedOrder && row.orderNumber <= draggedOrder)))
      orderedList = orderedList.sort((a, b) => a.orderNumber - b.orderNumber);
      if (orderedList.length > 0) {
        if (draggedOrder < droppedOrder) {
          orderedList[0].orderNumber = orderedList[orderedList.length - 1].orderNumber;
          for (let i = 1; i < orderedList.length; i++) {
            orderedList[i].orderNumber -= 1;
          }
        } else if (draggedOrder > droppedOrder) {
          orderedList[orderedList.length - 1].orderNumber = orderedList[0].orderNumber;
          for (let i = 0; i < orderedList.length - 1; i++) {
            orderedList[i].orderNumber += 1;
          }
        }
      }
      const requestVM =
      {
        menus: orderedList
      }
      this.configurationService.updateMenuOrder(requestVM).subscribe(res => {
        for (let i = 0; i < this.menuList.length; i++) {
          if (this.menuList[i].menuId == args.draggedNodeData.id) {
            this.menuList[i].orderNumber = orderedList.find(row => row.menuId == this.menuList[i].menuId).orderNumber;
          }
        }
        this.configurationService.menuListSubject.next(true);
      });
    }
  }
  submitSearch = (value) => {
    this.registerForm.controls['screenId'].setValue(value.screenId);
  }

  displayFn(screen): string {
    return screen instanceof Object ? screen.name : screen;
  }
}
