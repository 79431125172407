import { currencyRenderer, dateRenderer, dateTimeRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import * as _ from 'lodash';
import { tooltipcellRenderer } from "src/common/util/util";
import { calculateAgeByDate } from 'src/common/util/util';

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: {
    excelMode: 'windows',
  },
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getMyJourneyColDefs = (filterLookupModel: any = {}, componentSelf?: any) => {
  const colDefs: any = [
    {
      headerName: 'ID',
      field: 'journeyId',
      width: 80,
      aggFunc: 'count'
    },
    {
      headerName: 'Name',
      field: 'journeyName',
      width: 135,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Created On',
      field: 'createdOn',
      width: 140,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Source',
      field: 'originalReportName',
      width: 140,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Opportunity Type',
      field: 'oppertunityTypeDescription',
      width: 180,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Assigned Value',
      field: 'assignedValue',
      width: 165,
      cellRenderer: currencyRenderer,
      aggFunc: 'sum'
    },
    {
      headerName: 'Actionable by',
      field: 'actionableBy',
      width: 150,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Due by',
      field: 'dueBy',
      width: 115,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Age',
      width: 90,
      cellRenderer: (params) => {
        return `${params.node.rowPinned !== undefined ? '' : calculateAgeByDate(params?.data?.createdOn)}`;
      }
    },
    {
      headerName: 'Tenant',
      field: 'clientName',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Instance',
      field: 'instance',
      width: 175,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Action',
      field: 'cellAction',
      cellRenderer: 'myJourneyActionCellRenderer',
      pinned: 'right',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false
    }
  ];

  return colDefs;
}

export const getSharedWithMeColDefs = (filterLookupModel: any = {}, componentSelf?: any) => {
  const colDefs: any = [
    {
      headerName: 'ID',
      field: 'journey.journeyId',
      width: 80,
      aggFunc: 'count'
    },
    {
      headerName: 'Name',
      field: 'journey.journeyName',
      width: 135,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Created On',
      field: 'journey.createdOn',
      width: 140,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Created By',
      field: 'journey.createdByFullName',
      width: 140,
    },
    {
      headerName: 'Source',
      field: 'journey.originalReportName',
      width: 140,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Opportunity Type',
      field: 'journey.oppertunityTypeDescription',
      width: 180,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Assigned Value',
      field: 'journey.assignedValue',
      width: 165,
      cellRenderer: currencyRenderer,
      aggFunc: 'sum'
    },
    {
      headerName: 'Actionable by',
      field: 'journey.actionableBy',
      width: 150,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Due by',
      field: 'journey.dueBy',
      width: 115,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Age',
      width: 90,
      cellRenderer: (params) => {
        return `${params.node.rowPinned !== undefined ? '' : calculateAgeByDate(params?.data?.createdOn)}`;
      }
    },
    {
      headerName: 'Tenant',
      field: 'journey.clientName',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Instance',
      field: 'journey.instance',
      width: 175,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Action',
      field: 'cellAction',
      width: 110,
      cellRenderer: 'shareJourneyActionCellRenderer',
      pinned: 'right',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false
    }
  ];

  return colDefs;
}

export const getJourneyLogColDefs = (filterLookupModel: any = {}, componentSelf?: any) => {
  const colDefs: any = [
    {
      headerName: 'ID',
      field: 'journeyId',
      width: 80,
    },
    {
      headerName: 'Name',
      field: 'journey.journeyName',
      width: 140,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Created On',
      field: 'createdDate',
      width: 200,
      cellRenderer: dateTimeRenderer,
    },
    {
      headerName: 'User',
      field: 'userName',
      width: 130,
    },
    {
      headerName: 'Source',
      field: 'product',
      width: 165,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Tenant',
      field: 'clientName',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Instance',
      field: 'instance',
      width: 175,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Activity',
      field: 'actionPerformed',
      width: 165,
    },
    {
      headerName: 'Action',
      field: 'cellAction',
      width: 110,
      cellRenderer: 'journeyLogActionCellRenderer',
      pinned: 'right',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false
    }
  ];

  return colDefs;
}