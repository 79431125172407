export const privilegeOptions = [
    {
        id: 'Editor',
        value: 'Editor'
    },
    {
        id: 'Viewer',
        value: 'Viewer'
    },
    {
        id: 'Commenter',
        value: 'Commenter'
    }
];
