import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { LocalstorageService } from '../../services/localstorage.service';
import {
  USER_TOKEN_INFO,
  USER_INFO,
  SELECTED_LANGUAGE,
  USER_PREFERNCE_KEY,
  CLIENT_INFO,
  CLIENT_PREFERNCE_KEY,
  USER_ROLES,
  USER_INFO_USER,
} from '../../common/keys';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ConfigurationService } from '../../services/configuration.service';
import { NgxToasterService } from '../../services/ngx-toaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuList } from '../../common/pages';
import { environment } from '../../environments/environment';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@aspnet/signalr';
import { BotService } from '../../services/bot.service';
import { LazyLoadingService } from '../../services/lazy-loading.service';
import { AuthenticationService } from '../../services/Authentication-Services/authentication-services';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
  MediaMatcher,
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { UsersService } from '../../services/User-services/user-services';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { SharedDataService } from 'src/services/shared-data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  mobileQuery: MediaQueryList;
  public userInfo: any;
  public showChatScreen = false;
  public panelOpenState = false;
  public show = false;
  public reportPanelOpenState = false;
  public favourites = [];
  public leftMenuList = [];
  public notificationList = [];
  public menuList = MenuList;
  public selectedLanguage = 'en';
  public environment;
  private _mobileQueryListener: () => void;
  public _hubConnection: HubConnection;
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));
  public menuListChat = [
    // {
    //   menuId: '76',
    //   name: 'Chat',
    //   selector: 'app-landing-page',
    //   displayName: 'Landing Page',
    //   icon: 'text_snippet',
    //   component: LandingPageComponent
    // },
  ];
  document;
  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;
  @ViewChild(DashboardComponent) dashboard: DashboardComponent;
  telemetry = {
    menuId: '45',
    name: 'Telemetry',
    selector: 'app-user-interface',
    displayName: 'Telemetry',
  };
  private blockConnection = false;
  public iconnected = true;
  public transformedData = {};
  hidePanels;
  drawer: any;
  public loadedComponent;
  headerLogoUrl: any = '';
  public multiChatUser: any[] = [];

  constructor(
    public storage: LocalstorageService,
    public router: Router,
    public configurationService: ConfigurationService,
    public toastr: NgxToasterService,
    private dialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public spinner: NgxSpinnerService,
    public botService: BotService,
    public lazyService: LazyLoadingService,
    public auth: AuthenticationService,
    public media: MediaMatcher,
    private breakpointObserver: BreakpointObserver,
    changeDetectorRef: ChangeDetectorRef,
    private injector: Injector,
    public userService: UsersService,
    private _dataService: SharedDataService,
    public forecastLookupService: ForecastLookupService
  ) {
    this.environment = environment;
    this.selectedLanguage = this.storage.get(SELECTED_LANGUAGE) || 'en';
    this.document = document;
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.applicationTabRole();
    this.setHeaderLogoUrl();
    this.hidePanels = this.storage.get('USERCRED');
    this.menuGetlist();
    this.configurationService.menuListSubject$.subscribe((res) => {
      if (res) {
        this.menuGetlist();
      }
    });
    this.userInfo = this.storage.get(USER_INFO);
    this.getFavouriteList();
    this.getNotificationList();
    this.configurationService.favouriteListSubject$.subscribe((res) =>
      this.getFavouriteList()
    );
    if (!this.configurationService?.viewJourney?.observers?.length) {
      this.configurationService.viewJourney.subscribe((res) => {
        res.displayName = res.journeyName;
        this.addElementToDashboard(res);
      });
    }
    if (this._hubConnection) {
      this._hubConnection.onclose(() => {
        this.reconnect(5000);
      });
    }
    this.initSignalRHub();
    this.getHierarchyList();
    // this.initSignalRHub();
    this.getHierarchyList();
  }

  applicationTabRole() {
    const userRoles = this.storage.get(USER_ROLES);
    let roleId: any[] = [];
    userRoles.forEach(elementRole => {
      roleId.push(elementRole.roleId);
    });
    let roleInfo = {
      roleId: roleId.join(",")
    }
    if (roleInfo) {
      this.configurationService.ApplicationTabRoleGetByRoleId(roleInfo.roleId).subscribe(res => {
        this.multiChatUser = res;
      });
    }
  }

  getHierarchyList() {
    this.forecastLookupService.GetProductHierarchyList().subscribe(response => {
      this._dataService.setData(response.hierarchyMetaDataList);
    });

  }

  setHeaderLogoUrl() {
    const clientInfo = this.storage.get(CLIENT_INFO);
    const documentPath = _.get(clientInfo, 'document.documentPath', '');
    this.headerLogoUrl = documentPath || environment.headerLogoURL;
  }

  reconnect(delay: number) {
    if (this._hubConnection) {
      if (
        (this._hubConnection as any).connectionState ===
        HubConnectionState.Disconnected
      ) {
        this.iconnected = false;
        setTimeout(() => {
          // this.initSignalRHub(true);
        }, delay);
      }
    }
  }
  initSignalRHub = (reconnecting?: boolean) => {
    if (this.blockConnection) {
      return;
    }

    if (this._hubConnection) {
      if (
        (this._hubConnection as any).connectionState ===
        HubConnectionState.Connected
      ) {
        return;
      }
      this._hubConnection
        .stop()
        .then(() => {
          console.log('Connection stopped!');
        })
        .catch((err) => console.log('Error while stopping connection :('));
      this._hubConnection = null;
    }
    this.blockConnection = true;
    if (environment.NotificationHub) {
      this._hubConnection = new HubConnectionBuilder()
        .withUrl(
          `${environment.NotificationHub}?name=${this.storage.get(USER_INFO).userName
          }&role=dealer`
        )
        .build();
      this._hubConnection.on('GetServerPush', (res: any) => {
        this.botService.notificationSubject.next(res);
      });
      this._hubConnection.on('GetLoggedInUsers', (res: any) => {
        console.log(res);
      });
      this._hubConnection
        .start()
        .then(() => {
          this.iconnected = true;
          this.blockConnection = false;
          console.log('Connection started!');
          // if (reconnecting) {
          //   this.getTime();
          // }
        })
        .catch((err) => {
          console.log('Error while establishing connection :(');
          this.blockConnection = false;
        });
    }
  };


  public menuGetlist = () => {
    this.configurationService.getMenuList({ clientId: this.environment.ClientId }).subscribe(res => {
      this.leftMenuList = res.sort((a, b) => a.orderNumber - b.orderNumber);
      this.leftMenuList = this.leftMenuList.map(menu => ({ ...menu, id: menu.menuId, children: this.getChildren(menu, res) }));
      this.leftMenuList = this.leftMenuList.filter(menu => !menu.parentId);
      const nested = res.reduce((initial, value, index, original) => {
        if (!value.parentId) {
          if (initial.left.length) this.checkLeftOvers(initial.left, value)
          delete value.parentId
          value.root = true;
          initial.nested.push(value)
        }
        else {
          let parentFound = this.findParent(initial.nested, value)
          if (parentFound) this.checkLeftOvers(initial.left, value)
          else initial.left.push(value)
        }
        return index < original.length - 1 ? initial : initial.nested
      }, { nested: [], left: [] });
      this.leftMenuList.forEach(menu => {
        menu = nested.find(item => item.menuId === menu.menuId);
      });
    });
  }

  public getChildren = (menu, list) => {
    const filterd = list.filter((row) => row.parentId === menu.menuId);
    if (filterd && filterd.length > 0) {
      return filterd;
    } else {
      return [];
    }
  };
  checkLeftOvers(leftOvers, possibleParent) {
    for (let i = 0; i < leftOvers.length; i++) {
      if (leftOvers[i].parentId === possibleParent.menuId) {
        delete leftOvers[i].parentId;
        possibleParent.children
          ? possibleParent.children.push(leftOvers[i])
          : (possibleParent.children = [leftOvers[i]]);
        possibleParent.count = possibleParent.children.length;
        const addedObj = leftOvers.splice(i, 1);
        this.checkLeftOvers(leftOvers, addedObj[0]);
      }
    }
  }
  findParent(possibleParents, possibleChild) {
    let found = false;
    for (let i = 0; i < possibleParents.length; i++) {
      if (possibleParents[i].menuId === possibleChild.parentId) {
        found = true;
        delete possibleChild.parentId;
        if (possibleParents[i].children)
          possibleParents[i].children.push(possibleChild);
        else possibleParents[i].children = [possibleChild];
        possibleParents[i].count = possibleParents[i].children.length;
        return true;
      } else if (possibleParents[i].children)
        found = this.findParent(possibleParents[i].children, possibleChild);
    }
    return found;
  }

  getNestedChildren(arr, parentId) {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentId === parentId) {
        var children = this.getNestedChildren(arr, arr[i].menuId);

        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }
  onOpenedChange = (e: boolean) => {
    const mainContent = document.getElementById('mainContent');
    if (mainContent) {
      mainContent.className = e ? 'custom-width' : 'full-width';
    }
  };
  toggle(sidenav: MatDrawer) {
    const mainContent = document.getElementById('mainContent');
    if (mainContent) {
      mainContent.className = !sidenav.opened ? 'custom-width' : 'full-width';
    }
    sidenav.toggle();
  }
  public getFavouriteList = () => {
    const userId = this.userInfo.userId || 1;
    this.configurationService.favouriteGetlist({ userId }).subscribe((res) => {
      this.favourites = [];
      res.map((x) =>
        this.favourites.filter((a) => a.menuItemName === x.menuItemName)
          .length > 0
          ? null
          : this.favourites.push(x)
      );
    });
    // this.favourites = this.favourites.map(favourite => ({...favourite, menuId: Math.random()}));
  };
  public getNotificationList = () => {
    this.configurationService.AnnouncementGetAll({}).subscribe((res) => {
      this.notificationList = res ? res.data || [] : [];
    });
  };
  logOut() {
    const user = this.storage.get(USER_INFO);
    this.auth.logoutUser(user).subscribe((res) => { });
    this.storage.clear();
    this.router.navigate(['auth/Login']);
  }
  public openFavouritesInDashboard = (favourite) => {
    favourite = JSON.parse(JSON.stringify(favourite));
    this.configurationService.openFavouriteSubject.next(favourite);
  };
  OpenSimulation(i, value) {
    this.configurationService.simulationSubject.next({ key: i, value: value });
  }
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
  public deleteFromFavourites = (favourite, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.configurationService
      .favouriteActivate({ ...favourite, Active: false })
      .subscribe((res) => {
        this.favourites = this.favourites.filter(
          (listItem) => listItem.menuItemName !== favourite.menuItemName
        );
        this.configurationService.favouriteRemoveSubject.next(true);
        this.toastr.success(
          'Favorites',
          `${this.capitalizeFirstLetter(
            favourite.menuItemName
          )} removed from favorites successfully.`
        );
      });
  };
  openDialog(favourite, event = null): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.matDialogRef = this.dialog.open(this.dialogRef, {
      width: '500px',
      data: JSON.parse(JSON.stringify(favourite)),
    });
  }
  public onUpdate = (favourite: any) => {
    if (favourite) {
      this.spinner.show();
      this.configurationService.favouriteUpdate(favourite).subscribe(
        (res) => {
          const index = this.favourites.findIndex(
            (fav) => favourite.favouriteId === fav.favouriteId
          );
          if (index > -1) {
            this.favourites[index] = favourite;
          }
          this.spinner.hide();
          this.matDialogRef.close();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  };
  addElementToDashboard = (selector: any) => {
    if (selector.isPowerBiReport) {
      selector.data = selector.report;
    }
    this.getModule(selector);
    setTimeout(() => {
      this.configurationService.menuRendererSubject.next(selector);
    }, 100);
  };

  public getModule = (selector) => {
    switch (selector.module) {
      case 'Auth': {
        if (!this.configurationService.isAuthLoaded) {
          import('../auth/auth.module').then((m) => {
            this.loadedComponent = new m.AuthModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      case 'ReportingModule': {
        if (!this.configurationService.isReportingLoaded) {
          import('../reporting/reporting.module').then((m) => {
            this.loadedComponent = new m.ReportingModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      case 'ItemManagerModule': {
        if (!this.configurationService.isForecastManagerLoaded) {
          import('../item-manager/item-manager.module').then((m) => {
            this.loadedComponent = new m.ItemManagerModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      case 'MessagingModule': {
        if (!this.configurationService.isMessagingLoaded) {
          import('../../modules/messaging/messaging.module').then((m) => {
            this.loadedComponent = new m.MessagingModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      case 'ClusteringForecastModule': {
        if (!this.configurationService.isClusteringLoaded) {
          import('../../modules/clustering-forecast/clustering-forecast.module').then((m) => {
            this.loadedComponent = new m.ClusteringForecastModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      case 'ForecastEnginModule': {
        if (!this.configurationService.isIMLoaded) {
          import('../forecast-engin/forecast-engin.module').then((m) => {
            this.loadedComponent = new m.ForecastEnginModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      case 'DemoManagerModule': {
        if (!this.configurationService.isDemoModuleLoaded) {
          import('../demo-manager/demo-manager.module').then((m) => {
            this.loadedComponent = new m.DemoManagerModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      case 'ProcurementModule': {
        if (!this.configurationService.isProcurementLoaded) {
          import('../procurement/procurement.module').then((m) => {
            this.loadedComponent = new m.ProcurementModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      default:
        break;
    }
  };
  public removeSpaces = (menuName: string) => {
    if (menuName) {
      const string = menuName.trim();
      return string.replace('(Parent)', '');
    }
  };
  toggleAlert() {
    this.show = !this.show;
  }
  setSelectedLanguage = () => {
    this.storage.set(SELECTED_LANGUAGE, this.selectedLanguage);
    this.configurationService.languageSubject.next(this.selectedLanguage);
  };
  changeBodyClass = () => {
    if (document.body.className === 'light-theme') {
      document.body.className = 'dark-theme';
    } else {
      document.body.className = 'light-theme';
    }
  };
  public getName = (item) => {
    if (item) {
      switch (this.selectedLanguage) {
        case 'de':
          return item.germanName ? item.germanName : item.name;
        case 'zh':
          return item.chineseName ? item.chineseName : item.name;
        case 'en':
          return item.name;
        default:
          return item.name;
      }
    }
  };
  checkIfChildExist = (item) => {
    if (item && item.children && item.children.length > 0) {
      return item.children.find(
        (child) => child.children && child.children.length > 0
      );
    }
  };
  public openChatDialog = (template: TemplateRef<any>) => {
    this.matDialogRef = this.dialog.open(template, { width: '500px' });
  }
}
