<div class="journey-comment-parent">
  <div *ngFor="let comment of comments">
    <div class="journey-comment-body">
      <div *ngIf="isMyComment(comment)" class="bot-wrapper">
        <div class="right-chat">
          <div class="right-icon">
            <mat-icon fontSet="material-icons-outlined">
              account_circle
            </mat-icon>
            <p matTooltip="{{comment?.fullName}}">{{comment?.fullName}}</p>
          </div>
          <div class="container darker">
            <p> {{comment.comment}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!isMyComment(comment)" class="bot-wrapper">
        <div class="d-flex align-items-center">
          <div class="right-icon d-flex flex-column align-items-center mr-2">
            <mat-icon fontSet="material-icons-outlined">
              account_circle
            </mat-icon>
            <p matTooltip="{{comment?.fullName}}">{{comment?.fullName}}</p>
          </div>
          <div class="container response-back">
            <p> {{comment.comment}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row journey-chat">
  <div class="col-12 col-md-12">
    <img src="assets/images/search-logo.png" alt="search-logo" />
    <form action="">
      <mat-form-field class="example-full-width" floatLabel="never">
        <input
          [style.font-size]="'18px'"
          matInput
          placeholder="Message . . ."
          aria-label="Search"
          [formControl]="comment"
          (keydown.enter)="onKeydown($event)"
          (keyup.enter)="comment?.value && saveComment()"
        />
      </mat-form-field>
    </form>
    <mat-icon class="cursor" color="accent" matSuffix (click)="comment?.value && saveComment()">send
    </mat-icon>
    <!-- <button (click)="saveComment()" class="btn btn-primary">Save</button> -->
  </div>
</div>
