import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ColumnApi, ColumnState, GridApi, GridOptions } from 'ag-grid-community';
import { defaultColDef, getSharedWithMeColDefs } from '../ag-grid/grid-options';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ShareJourneyActionCellRenderer } from '../ag-grid/share-journey-action-cell-renderer.component';
import { PersistentContextService } from 'src/services/persistent-context-services/persistent-context.service';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { JOURNEY_SHARED_LIST } from 'src/common/keys';

@Component({
  selector: 'app-journey-shared-with-me',
  templateUrl: './journey-shared-with-me.component.html',
  styleUrls: ['./journey-shared-with-me.component.scss'],
})
export class JourneySharedWithMeComponent implements OnInit, OnDestroy {

  paginationPageSize = 50;
  cacheBlockSize = 50;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  public defaultColDef = defaultColDef;
  public columnDefs = getSharedWithMeColDefs({}, this);
  gridData: any = [];
  frameworkComponents = {
    shareJourneyActionCellRenderer: ShareJourneyActionCellRenderer
  };
  public gridColumnState: ColumnState[] = [];
  screenPreference: any;
  public actionSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: any,
    public userService: UsersService,
    public dialogRef: MatDialogRef<any>,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    private persistentContextService: PersistentContextService,
    private userPreferenceService: UserPreferenceService,
  ) { }

  ngOnInit() {
    this.fetchSharedWithMe();
    this.actionSubscription = this.persistentContextService.getShareJourneyGridActionSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'visibility': {
        actionObject.data.journey.privilege = actionObject.data.privilege || '';
        this.viewReport(actionObject.data.journey, actionObject.data.id);
        break;
      }
    }
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(JOURNEY_SHARED_LIST, JOURNEY_SHARED_LIST);
    this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
    this.colApi.applyColumnState({ state: this.gridColumnState });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.getUserSecreenPrefereence();
  }

  onGridStateChange(e) {
    this.gridColumnState = this.colApi.getColumnState();
    this.screenPreference.columnState = this.gridColumnState;
    this.updateScreenPreference();
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  filterChanged(params) {
    this.getBottomTotalRowPinned();
  }

  private fetchSharedWithMe() {
    this.configService
      .getJourneyCollaboration(null, this.storage.get(USER_ID), null)
      .subscribe((res) => {
        this.gridData = res;
        this.getBottomTotalRowPinned();
      });
  }

  getBottomTotalRowPinned() {
    this.gridApi.setPinnedBottomRowData([]);
    setTimeout(() => {
      // get the total number of rows
      const rowCount = this.gridApi.getDisplayedRowCount();
      // loop through the displayed rows and get the data
      const rowData: any = [];
      for (let i = 0; i < rowCount; i++) {
        const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
        rowData.push(rowNode.data);
      }
      const assignedValueSum = _.sumBy(rowData, a => a.journey.assignedValue);
      const pinnedRow: any = { journey: { journeyId: 'Total:', journeyName: `${rowCount} Journeys`, assignedValue: assignedValueSum } };
      this.gridApi.setPinnedBottomRowData([pinnedRow]);
    }, 300);
  }

  viewReport(element: any, collaborationId: number) {
    console.log('element', element);
    if (element?.metaData) {
      const data = JSON.parse(element.metaData);
      data.isPowerBiReport = true;
      data.createdBy = element.createdBy;
      data.createdOn = element.createdOn;
      data.journeyId = element.journeyId;
      data.journeyName = element.journeyName;
      data.originalGroupId = element.originalGroupId;
      data.originalReportId = element.originalReportId;
      data.originalReportName = element.originalReportName;
      data.metaData = element.metaData;
      data.privilege = element.privilege;
      this.configService.viewJourney.emit(data);
      this.dialogRef.close();

      if (collaborationId > 0) {
        this.configService
          .UpdateJourneyViewedAt({ Id: collaborationId })
          .subscribe((res: any) => {
            console.log('journey-view notification updated');
          });
      }
    }
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
  }
}
