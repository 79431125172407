<div class="container-fluid mapping-manager-page">
  <div class="row product-grid-filter-row mt-10">
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()" class="seasonalityKey-width"
          placeholder="{{productHierarchyService.getProductNodeLabel(2)}}" name="itemNode2" [(ngModel)]="itemNode2"
          [ngModelOptions]="{standalone: true}" [ngModelOptions]="{standalone: true}"
          [matAutocomplete]="combinationAuto" (input)="_filterCombinationByKey($event)"
          (focus)="onCombinationFocus($event)" />
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
        <mat-autocomplete [displayWith]="displayCombinationFn" #combinationAuto="matAutocomplete"
          (optionSelected)="selectCombinationManger($event.option.value)">
          <mat-option *ngFor="let combination of node2list | async" [matTooltip]="combination.node2"
            [value]="combination" matTooltipClass="tooltips">
            {{combination.node2}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()" class="seasonalityKey-width"
          placeholder="{{productHierarchyService.getProductNodeLabel(1)}}" name="itemNode1" [(ngModel)]="itemNode1"
          [ngModelOptions]="{standalone: true}" [ngModelOptions]="{standalone: true}"
          [matAutocomplete]="combinationAutoNode1" (input)="_filterCombinationByKey2($event)"
          (focus)="onCombinationFocus2($event)" />
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
        <mat-autocomplete [displayWith]="displayCombinationFn2" #combinationAutoNode1="matAutocomplete"
          (optionSelected)="selectCombinationManger2($event.option.value)">
          <mat-option *ngFor="let combination of node1list | async" [matTooltip]="combination.node1"
            [value]="combination" matTooltipClass="tooltips">
            {{combination.node1}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput placeholder="Release From Date" (dateChange)="applyFilterEvent()" [matDatepicker]="fromDate"
          [(ngModel)]="releaseFromDate">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput placeholder="Release To Date" (dateChange)="applyFilterEvent()" [matDatepicker]="toDate"
          [(ngModel)]="releaseToDate">
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <mat-select placeholder="Account" [(ngModel)]="locationNode2" (selectionChange)="applyFilterEvent()">
          <mat-option *ngFor="let location of locationNodeListValue"
            [value]="location.node2">{{location.node2}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <mat-select placeholder="Active / InActive" [(ngModel)]="relationship" (selectionChange)="applyFilterEvent()">
          <mat-option *ngFor="let status of statusList" [value]="status.statusId">{{status.statusName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-clear-btn mr-2">
      <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
    </div>
  </div>
  <div class="mapping-action-btn-bar">

    <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>

    <div class="grid-actions inline-flex">
      <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
        (click)="exportCombinationListGridData()">
        <i class="material-icons">
          insert_drive_file
        </i>
      </button>
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{totalRows}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{totalRows}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>

    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="actionMenu">Actions</button>
    <mat-menu #actionMenu="matMenu">
      <!-- (click)="openForecastSKUDialog(true)" -->
      <!-- (click)="selectedRows.length && updateForecaseItemStatus(true)" -->
      <button mat-menu-item (click)="addRelationship(true)">Activate</button>
      <button mat-menu-item (click)="addRelationship(false)">Deactivate</button>

    </mat-menu>


    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="importExportMenu">Export/Import</button>
    <mat-menu #importExportMenu="matMenu">
      <button mat-menu-item (click)="onExportCombinationTemplate()">Export Template</button>
      <button mat-menu-item (click)="importDataByTemplate()">Import
        <input class="hidden" type="file" id="upload-combination-manager-data" name="upload-combination-manager-data"
          accept=".csv" (change)="validateUploadedCSV($event)">
      </button>
    </mat-menu>

    <button class="add-icon-btn ml-10" (click)="openCombinationSetup()" matTooltip="Add Item Setup" mat-icon-button
      color="primary" type="button">
      <i class="material-icons">
        add_circle
      </i>
    </button>

  </div>

  <div class="all-show-grid" *ngIf="isGridLoaded">
    <ag-grid-angular #mappedSKUsGrid id="outlier-mapped-sku-data-grid" class="ag-theme-material mapped-skus-grid"
      [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData"
      [rowSelection]="rowSelection" [animateRows]="true" [pagination]="true" [frameworkComponents]="frameworkComponents"
      [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType" (selectionChanged)="rowSelectionChanged($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>



  <div style="width: 0; height: 0;" *ngIf="isGridLoaded">
    <ag-grid-angular #itemExportTemplateHidden class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
      [columnDefs]="columnHiddenDefs" [defaultColDef]="defaultColDef" (gridReady)="onHiddenGridReady($event)"
      [modules]="modules">
    </ag-grid-angular>
  </div>

</div>