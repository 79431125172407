import { dateRenderer, statusRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import { tooltipcellRenderer } from "src/common/util/util";
import { AppInjector } from "src/shared/shared.module";

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getColDefs = (locationHierarchyMetadataList: any = [], locationNodeMetadataList: any = [],
  productHierarchyMetadataList: any = [], productNodeMetadataList: any = [],) => {

  const locationHierarchyMetadataColDef: any = [];
  const locationNodeMetadataColDef: any = [];
  const productHierarchyMetadataColDef: any = [];
  const productNodeMetadataColDef: any = [];

  locationHierarchyMetadataList.forEach(item => {
    locationHierarchyMetadataColDef.push({
      headerName: item.locationHierarchyName,
      field: `l${item.locationHierarchyLevel}Value`,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    });
  });
  locationNodeMetadataList.forEach(node => {
    locationNodeMetadataColDef.push({
      headerName: node.locationHierarchyNodeName,
      field: `locationNode${node.locationHierarchyNodeLevel}`,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    });
  });
  productHierarchyMetadataList.forEach(item => {
    productHierarchyMetadataColDef.push({
      headerName: item.productHierarchyName,
      field: `h${item.productHierarchyLevel}Value`,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    });
  });
  productNodeMetadataList.forEach(node => {
    productNodeMetadataColDef.push({
      headerName: node.productHierarchyNodeName,
      field: `itemNode${node.productHierarchyNodeLevel}`,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    });
  });


  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    ...locationHierarchyMetadataColDef,
    ...locationNodeMetadataColDef,
    {
      headerName: 'Relationship',
      field: 'relationship',
      valueFormatter: params => {  return params.value ? 'Active': 'InActive' }
    },

    ...productNodeMetadataColDef,
    ...productHierarchyMetadataColDef,

    {
      headerName: 'Age Days',
      field: 'ageDays',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    },


  ];

  return colDefs;
}


export const getExportTemplateColDefs = (locationHierarchyMetadataList: any = [], locationNodeMetadataList: any = [],
  productHierarchyMetadataList: any = [], productNodeMetadataList: any = [],) => {

  const locationHierarchyMetadataColDef: any = [];
  const locationNodeMetadataColDef: any = [];
  const productHierarchyMetadataColDef: any = [];
  const productNodeMetadataColDef: any = [];

  locationHierarchyMetadataList.forEach(item => {
    locationHierarchyMetadataColDef.push({
      headerName: item.locationHierarchyName,
      children: [{
        headerName: `L${item.locationHierarchyLevel}`,
        field: `l${item.locationHierarchyLevel}Value`
      }]
    });
  });
  locationNodeMetadataList.forEach(node => {
    if (node.locationHierarchyNodeLevel === 2) {
      locationNodeMetadataColDef.push({
        headerName: node.locationHierarchyNodeName,
        children: [{
          headerName: `Node${node.locationHierarchyNodeLevel}`,
          field: `node${node.locationHierarchyNodeLevel}`
        }]
      });
    }
  });
  productHierarchyMetadataList.forEach(item => {
    productHierarchyMetadataColDef.push({
      headerName: item.productHierarchyName,
      children: [{
        headerName: `H${item.productHierarchyLevel}`,
        field: `h${item.productHierarchyLevel}Value`
      }]
    });
  });
  productNodeMetadataList.forEach(node => {
    if (node.productHierarchyNodeLevel === 2) {
      productNodeMetadataColDef.push({
        headerName: node.productHierarchyNodeName,
        children: [{
          headerName: `Node${node.productHierarchyNodeLevel}`,
          field: `node${node.productHierarchyNodeLevel}`
        }]
      });
    }
  });


  const colDefs: any = [

     {
      headerName: 'Account',
      children: [{
       headerName: 'LocationNode1',
       field:'locationNode1'
      }
    ],
    },
    {
      headerName: 'SKU',
      children: [{
       headerName: 'ItemNode1',
       field:'ItemNode1'
      }
    ],
    },
    {
      headerName: 'Active/InActive',
      children: [{
       headerName: 'Relationship',
       field:'relationship'
      }
    ],
    },

  ];

  return colDefs;
}
