import * as _ from 'lodash';
import * as  moment from 'moment';

export const getFilterModel = (gridFilters) => {
  const filterFields = _.keys(gridFilters);
  const filterModel: any = [];
  filterFields.forEach(field => {
    filterModel.push({
      field: field,
      filterType: gridFilters[field].filterType,
      type: gridFilters[field].type || gridFilters[field].filterType,
      filter: gridFilters[field].filter,
      filterValues: gridFilters[field].values,
      ...gridFilters[field].dateFrom && { dateFrom: gridFilters[field].dateFrom }
    });
  });
  return filterModel;
}

export const getSortModel = (gridSorting) => {
  const sortModel: any = [];
  gridSorting.forEach(field => {
    if (field.sort) {
      sortModel.push({
        field: field.colId,
        order: field.sort
      });
    }
  });
  return sortModel;
}


export const numberValueFormatter2Decimal = (params) => {
  if (params.value) {
    return Number(Number(params.value).toFixed(2));
  } else {
    return '';
  }
}


export const tooltipcellRenderer = (params) => {
  return params.value ? `<span title="${params.value}">${params.value}</span>` : '';
}

export const currencyRenderer = (params) => {
  return `${params.value ? '$' + Number(params.value).toFixed(2) : ''}`;
}

export const SASTokenParser = (sasToken) => {
  const params = sasToken.split('&');
  let queryString = '';
  params.forEach(param => {
    const keyValue = param.split('=');
    queryString = `${queryString}&${keyValue[0]}=${encodeURIComponent(keyValue.slice(1, keyValue.length).join('='))}`;
  });
  return queryString.slice(1);
}

export const calculateAgeByDate = (date: Date | string) => {
  try {

    const dateBy = new Date(date);
    const currentDate = new Date();

    const years = currentDate.getFullYear() - dateBy.getFullYear();
    const months = currentDate.getMonth() - dateBy.getMonth();
    let days = currentDate.getDate() - dateBy.getDate();

    let weeks = 0;
    if (days < 0) {
      weeks = Math.floor((days + 7) / 7);
      days = days + weeks * 7;
    }
    const totalDays = years * 365 + months * 30 + days;
    const totalWeeks = Math.floor(totalDays / 7);
    const remainingDays = totalDays % 7;

    return `${years > 0 ? years + 'y,' : ''} ${months > 0 ? months + 'm,' : ''} ${totalWeeks > 0 ? totalWeeks + 'w' : ''} ${(remainingDays > 0) ? remainingDays + 'd' : (totalWeeks > 0 ? '' : 'today')}`;
  } catch (err) {
    return `Not a date`;
  }
};

export const datetimeLocalzone = (value) => {
  return `${value ? moment.utc(value).local().format('MM/DD/YYYY hh:mm:ss A') : ''}`;
}

