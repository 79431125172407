import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import { ForecastEngineItemImagesComponent } from './forecast-engine-item-images/forecast-engine-item-images.component';
import { CreateProductHierarchyTree } from 'src/common/item-tree-dorpdown';
import { TreeviewItem } from 'ngx-treeview';
import { ForecastItemManagerService } from 'src/services/forecast-services/item-manager.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ProductHierarchyService } from 'src/services/setup-services/product-hierarchy.service';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';


interface IImageObj { id: string, name: string, file: File, content: '', extention: string };

@Component({
  selector: 'app-item-setup',
  templateUrl: './item-setup.component.html',
  styleUrls: ['./item-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemSetupComponent implements OnInit {

  @Input() data?: any;
  isEdit: boolean = false;

  itemSetupForm = new FormGroup({
    dimItemId: new FormControl(null),
    productHierarchyLastLevelId: new FormControl(null, Validators.required),
    node1: new FormControl(null, Validators.required),
    node2: new FormControl(null, Validators.required),
    productSeasonId: new FormControl(null, Validators.required),
    productSourceId: new FormControl(null, Validators.required),
    productSupplierId: new FormControl(null, Validators.required),
    retailPrice: new FormControl(null, Validators.required),
    msrp: new FormControl(null, Validators.required),
    cost: new FormControl(null, Validators.required),
    grossMargin: new FormControl(null, Validators.required),
    releaseDate: new FormControl(null),
    endofLifeDate: new FormControl(null),
    active: new FormControl(true)
  });
  udaList: any = [];
  likeItemList: any = [];
  public selectedProductHierarchies = [];
  itemData: any;

  public productHierarchyTreeList: TreeviewItem[];
  endOfLifeDateMin: Date;
  filteredItemList: Observable<any[]>;
  filteredUdaTypeList: Observable<any[]>;
  filteredUdaList: Observable<any[]>;

  imagesList: IImageObj[] = new Array<IImageObj>();

  productHierarchyList: any = [];
  productSeasonList: any = [];
  productSourceList: any = [];
  productSupplierList: any = [];
  productUdaTypeList: any = [];
  productUdaList: any = [];
  likeProductList: any = [];

  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    public productHierarchyService: ProductHierarchyService,
    public itemManagerService: ForecastItemManagerService,
  ) { }

  ngOnInit(): void {
    this.addCustomValidators();
    this.getLookupData();
    this.initEditMode();
    for (let i = 0; i <= 5; i++) {
      const obj: IImageObj = { id: '', name: '', file: null, content: '', extention: ''};
      this.imagesList[i] = obj;
    }
  }

  uploadImg(index: number) {
    if (this.imagesList[index].name) {
      this.toastr.warning('Warning', 'Image already exists. Remove it before uploading a new one.');

    } else {
      document.getElementById('imgUploadBtn').setAttribute('arrayIndex', index.toString());
      document.getElementById('imgUploadBtn').click();
    }
  }

  onFileSelect(event) {
    const index = Number(document.getElementById('imgUploadBtn').getAttribute('arrayIndex'));
    const files = event.target.files as FileList;
    const file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagesList[index].content = reader.result as any;
      this.imagesList[index].name = file.name;
      this.imagesList[index].file = file;
      this.imagesList[index].extention = file.name.split('.').pop();
      event.target.value = null;
    }
    reader.onerror = e => {
      event.target.value = null;
    }
  }

  removeImg(index: number) {

    if (this.imagesList[index].id) {
      this.spinner.show();
      const obj = {
        dimItemId: this.itemData.dimItemId,
        documentPath: this.imagesList[index].content,
        documentName: this.imagesList[index].name,
        documentId: this.imagesList[index].id,
        documentExtension: this.imagesList[index].extention
      };
      this.itemManagerService.deleteItemDocument(obj)
      .subscribe((res: any) => {
        this.imagesList[index].content = '';
        this.imagesList[index].name = '';
        this.imagesList[index].file = null;
        this.imagesList[index].id = null;
        this.imagesList[index].extention = null;
        this.toastr.success('Success', 'Image removed successfully');
        this.spinner.hide;
      },
      err => {
        this.toastr.error('Error', 'Failed to remove Image');
        this.spinner.hide;
      });
    } else {
      this.imagesList[index].content = '';
      this.imagesList[index].name = '';
      this.imagesList[index].file = null;
      this.imagesList[index].id = null;
      this.imagesList[index].extention = null;
    }

  }

  get productSourceId() {
    return this.itemSetupForm.get('productSourceId');
  }

  get filteredProductSupplierList() {
    return this.productSourceId.value ? _.filter(this.productSupplierList, a => a.productSourceId === this.productSourceId.value) : this.productSupplierList;
  }

  addCustomValidators() {
    setTimeout(() => {
      this.itemSetupForm.controls['endofLifeDate'].setValidators([Validators.required, this.endOfLifeValidator]);
    }, 0);
  }

  endOfLifeValidator(control: FormControl) {
    let releaseDate = control.parent.controls['releaseDate'].value;
    const endofLifeDate = control.value;
    if (releaseDate) {
      releaseDate = new Date(releaseDate);
      if (endofLifeDate < releaseDate) {
        return {
          endofLifeDate: 'Release date should be less then End of Life.'
        };
      }
      return null;
    } else {
      return null;
    }
  }

  calculateGrossMargin() {
    const cost = this.itemSetupForm.get('cost').value ? Number(this.itemSetupForm.get('cost').value) : 0
    const retailPrice = this.itemSetupForm.get('retailPrice').value ? Number(this.itemSetupForm.get('retailPrice').value) : 0;
    const grossMargin = (retailPrice ? (retailPrice - cost) / retailPrice : 0).toFixed(2);

 this.itemSetupForm.controls.grossMargin.setValue(parseFloat(grossMargin) );
  }

  getLookupData() {
    this.itemManagerService.ProductHierarchyTreeGetList().subscribe(response => {
      this.productHierarchyList = response;
      this.createProductHierarchyDropdownList(response);
    });
    this.itemManagerService.ProductSeasonGetList().subscribe(response => {
      this.productSeasonList = response;
    });
    this.itemManagerService.ProductSourceGetList().subscribe(response => {
      this.productSourceList = response;
    });
    this.itemManagerService.ProductSupplierGetList().subscribe(response => {
      this.productSupplierList = response;
    });
    this.itemManagerService.ProductUDATypeGetList().subscribe(response => {
      this.productUdaTypeList = response;
    });
    this.itemManagerService.ProductUDAGetList().subscribe(response => {
      this.productUdaList = response;
    });

  }

  getLikeItemList() {
    this.spinner.show();
    this.itemManagerService.LikeItemGetList().pipe(finalize(()=> this.spinner.hide())).subscribe(response => {
      this.likeProductList = response;
    });
  }

  initEditMode() {
    const data = JSON.parse(this.data ? this.data : {});
    this.isEdit = data.isEdit ? data.isEdit : false;

    if (this.isEdit) {
      this.getLikeItemList();
      this.dimItemGetById(_.get(data, 'itemData.dimItemId', 0));
    }
  }

  dimItemGetById(dimItemId) {
    this.spinner.show();
    this.itemManagerService.DimItemGetById(dimItemId).pipe(finalize(()=> this.spinner.hide())).subscribe(response => {
      this.itemData = response;
      this.initializeForm();
    });
  }

  initializeForm() {
    this.createProductHierarchyDropdownList(this.productHierarchyList, [this.itemData.productHierarchyLastLevelId]);
    this.itemSetupForm.patchValue(this.itemData);
    this.udaList = this.itemData.udaList || [];
    this.likeItemList = this.itemData.likeItems || [];
    this.createDocumentList(this.itemData.documents);
  }

  createDocumentList(documentsList) {
    // reset list
    for (let i = 0; i <= 5; i++) {
      const obj: IImageObj = { id: '', name: '', file: null, content: '', extention: ''};
      this.imagesList[i] = obj;
    }
    // populate imagesList array
    documentsList.forEach((d: any) => {
      this.imagesList[Number(d.sequenceOfImage) - 1].content = d.documentPath;
      this.imagesList[Number(d.sequenceOfImage) - 1].id = d.documentId;
      this.imagesList[Number(d.sequenceOfImage) - 1].name = d.documentName;
      this.imagesList[Number(d.sequenceOfImage) - 1].extention = d.documentExtension;
    });
  }

  createProductHierarchyDropdownList(itemData, selectedValues: any = []) {
    this.productHierarchyTreeList = CreateProductHierarchyTree(itemData, selectedValues);
  };

  deleteLikeItem(item, index) {
    if (item.dimLikeItemId) {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Delete Like item`,
          confirmationMessage: `Are you sure you want to delete this like item?`
        }
      });

      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.itemManagerService.DeleteLikeItem(item.dimLikeItemId).subscribe(res => {
            if (res) {
              this.likeItemList.splice(index, 1);
              this.spinner.hide();
              this.toastr.success('Success', `Like item deleted successfully.`);
            };
          }, err => {
            this.toastr.error('Error', `System failed to delete like item.`);
            this.spinner.hide();
          });
        }
      });
    } else {
      this.likeItemList.splice(index, 1);
    }
  }

  releaseDateChange(event) {
    this.setEndOfLifeDateMin();
  }

  setEndOfLifeDateMin() {
    const releaseDate = this.itemSetupForm.get('releaseDate').value;
    if (releaseDate) {
      this.endOfLifeDateMin = new Date(releaseDate);
    }
  }

  deleteUDARowItem(uda, index) {
    if (uda.dimItemProductUDAId) {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Delete UDA`,
          confirmationMessage: `Are you sure you want to delete this UDA?`
        }
      });

      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.itemManagerService.DeleteUDA(uda.dimItemProductUDAId).subscribe(res => {
            if (res) {
              this.udaList.splice(index, 1);
              this.spinner.hide();
              this.toastr.success('Success', `UDA deleted successfully.`);
            };
          }, err => {
            this.toastr.error('Error', `System failed to delete UDA.`);
            this.spinner.hide();
          });
        }
      });
    } else {
      this.udaList.splice(index, 1);
    }

  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.imagesList, event.previousIndex, event.currentIndex);
  }

  public deleteImage = () => {
  }

  supplierChange(event) {

  }

  addLikeItem() {
    if (this.likeItemList.length < 5) {
      this.likeItemList.push({
        node1: null,
      });
    }
  }

  addUDA() {
    if (this.udaList.length < 10) {
      this.udaList.push({
        productUDATypeName: null,
        productUDAName: null
      });
    }
  }

  _filterItemByKey(event, item) {
    let value = _.toLower(event.target.value);
    if (!value) {
      item.node1 = null;
    }
    const itemsList = _.filter(this.likeProductList, item => { return _.toLower(item.node1).indexOf(value) > -1 })
    this.filteredItemList = of(itemsList);
  }


  onLikeItemKeyFocus(event) {
    this.filteredItemList = of(this.likeProductList);
  }


  displayItemKeyFn(item): string {
    return item && (typeof item === 'object' && item.node1) ? item.node1 : item;
  }

  selectLikeItemKey = (selectedItem, savedItem) => {
    savedItem.node1 = selectedItem.node1;
  }



  setProductHierarcyValue(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'ProductHierarchy': {
        this.selectedProductHierarchies = selectedDropdown.value;
        this.itemSetupForm.get('productHierarchyLastLevelId').setValue(this.selectedProductHierarchies[0]);
        break;
      }
    }
  };

  _filterUdaType(event, uda) {
    let value = _.toLower(event.target.value);
    if (!value) {
      uda.productUDATypeName = null;
      uda.productUDAName = null;
    }
    const udaTypeList = _.filter(this.productUdaTypeList, item => { return _.toLower(item.productUDATypeName).indexOf(value) > -1 })
    this.filteredUdaTypeList = of(udaTypeList);
  }

  onUdaTypeFocus() {
    this.filteredUdaTypeList = of(this.productUdaTypeList);
  }

  displayUdaTypeFn(item): string {
    return item && (typeof item === 'object' && item.productUDATypeName) ? item.productUDATypeName : item;
  }

  selectUdaType(selectedUda, savedUda) {
    savedUda.productUDATypeName = selectedUda.productUDATypeName;
    savedUda.productUDAName = null;
  }


  _filterUda(event, uda) {
    let value = _.toLower(event.target.value);
    if (!value) {
      uda.productUDAName = null;
    }
    const productUDATypeName = uda.productUDATypeName;
    const udaFilterListWithType = _.filter(this.productUdaList, a => productUDATypeName ? a.productUDATypeName = productUDATypeName : true);
    const udaList = _.filter(udaFilterListWithType, item => { return _.toLower(item.productUDAName).indexOf(value) > -1; })
    this.filteredUdaList = of(udaList);
  }

  onUdaFocus(event, uda) {
    const productUDATypeName = uda.productUDATypeName;
    const udaFilterListWithType = _.filter(this.productUdaList, a => productUDATypeName ? a.productUDATypeName = productUDATypeName : true);
    this.filteredUdaList = of(udaFilterListWithType);
  }

  displayUdaFn(item): string {
    return item && (typeof item === 'object' && item.productUDAName) ? item.productUDAName : item;
  }

  selectUda(selectedUda, savedUda) {
    savedUda.productUDAName = selectedUda.productUDAName;
    savedUda.productUDATypeName = selectedUda.productUDATypeName;
  }

  isAllLikeItemValid() {
    const isAllValuesAvailable = this.likeItemList.length === _.compact(this.likeItemList).length;
    const isAllValuesUnique = this.likeItemList.length === _.uniqBy(this.likeItemList, a => a.node1).length;
    return isAllValuesUnique && isAllValuesAvailable;
  }

  isValidUDAList() {
    let isValidUDA = true;
    this.udaList.every(uda => {
      if (!uda.productUDAName && !uda.productUDATypeName) {
        isValidUDA = false;
        return false;
      }
      return true;
    });
    return isValidUDA;
  }

  saveItem() {
    const isValidLikeItem = this.isAllLikeItemValid();
    const isValidUDA = this.isValidUDAList();

    if (this.itemSetupForm.valid
      && isValidLikeItem
      && isValidUDA
      && this.selectedProductHierarchies.length < 2) {
      const payload = {
        ...this.itemSetupForm.value,
        udaList: this.udaList,
        likeItems: this.likeItemList
      };
      if (this.isEdit) {
        this.spinner.show();

        this.itemManagerService.ItemUpdate(payload).subscribe(response => {
          this.saveDocuments(this.itemData.dimItemId);
          this.toastr.success('Success', 'Item updated successfully.');
          this.itemManagerService.setProductSetupActionSubject(true);
          // this.spinner.hide()
        }, err => {
          this.toastr.error('Error', 'System failed to create item.');
        });
      } else {
        this.spinner.show();
        this.itemManagerService.ItemAdd(payload).subscribe((response: any) => {
          this.saveDocuments(response.dimItemId);
          this.isEdit = true;
          this.toastr.success('Success', 'Item created successfully.');
          this.itemManagerService.setProductSetupActionSubject(true);
          // this.spinner.hide();

        }, err => {
          this.toastr.error('Error', 'System failed to create item.');
        });
      }

    } else {
      this.toastr.error('Error', 'Please fill the form correctly.');
    }
  }

  saveDocuments(id) {
    this.spinner.show();
    const documents = this.populateDocumentsList();

    if (documents.length) {
      const formData = new FormData();
      documents.forEach((element, index) => {
        formData.append('files', element.file || null);
        formData.append('documentOrder[]', element.documentOrder);
        formData.append('documentIds[]', element.id || 0);
      });
      formData.append('dimItemId', id);
      this.itemManagerService.uploadItemDocuments(formData).pipe(finalize(()=> this.spinner.hide()))
        .subscribe((res: any) => {
          this.isEdit = true;
          this.dimItemGetById(id);
          this.getLikeItemList();
        },
          err => {
            this.toastr.error('Error', 'Unable to update the uploaded images.');
          });
    }
    else {
      this.spinner.hide();
      this.dimItemGetById(this.itemData.dimItemId);

    }
  }

  populateDocumentsList() {
    const result = [];
    this.imagesList.forEach((e, index) => {
      if (e.name) {
        const docObj = {
          file: e.file || null,
          documentName: e.name,
          displayName: e.name,
          documentOrder: index + 1,
          id: e.id || null
        };
        result.push(docObj)
      }
    });
    return result;
  }

}
