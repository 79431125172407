export class JourneyCommentsRequestVM {
  id?: number;
  comment: string;
  createdBy: number;
  createdAt?: Date;
  journeyId: number;
  fullName?: string;
  clientConfigurationId?: number;
  instance?: string;
}
