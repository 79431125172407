import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NgxToasterService } from './../../../../../services/ngx-toaster.service';
import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/services/shared-data.service';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductHierarchyService } from 'src/services/setup-services/product-hierarchy.service';

@Component({
  selector: 'app-product-hierarchy',
  templateUrl: './product-hierarchy.component.html',
  styleUrls: ['./product-hierarchy.component.css']
})
export class ProductHierarchyComponent implements OnInit {

  hierarchyNodes: any[] = [{
    productHierarchyNodeLevel: 1,
    productHierarchyNodeName: ''

  },
  {
    productHierarchyNodeLevel: 2,
    productHierarchyNodeName: ''
  }];
  hierarchyLevels: any[] = [];
  hierarchyLevel: any;
  hierarchyName: any;
  isDisable: boolean;

  constructor(public productHierarchyService: ProductHierarchyService,
    public toastr: NgxToasterService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialog: MatDialog,
    private _dataService: SharedDataService) { }


  get addLevel(): boolean {
    return this.hierarchyLevels.length < this.productHierarchyService.productHierarchyMaximumLevel;
  }

  ngOnInit() {
    this.getHierarchyList();
    this.getHierarchyNodeMetadataList();
  }

  getHierarchyList() {
    this.productHierarchyService.GetProductHierarchyList().subscribe(response => {
      if (response) {
        this.hierarchyLevels = response.productMetadataList;
        this._dataService.setData(response.productMetadataList);
        if (response.productMetadataList.length >= 2) {
          this.isDisable = true;
        }
      }
    },
      err => {
        this.toastr.error('Error', 'System failed to Get Product Hierarchy.');
      });

  }

  getHierarchyNodeMetadataList() {
    this.productHierarchyService.GetHierarchyNodeMetadataList().subscribe(response => {
      if (response.productHierarchyNodeMetaList.length > 0) {
        this.hierarchyNodes = response.productHierarchyNodeMetaList;
      }
    },
      err => {
        this.toastr.error('Error', 'System failed to Get Product Hierarchy.');
      });

  }


  isAllHierarchyValueAvailable(hierarchyValuesList) {
    const valuesListOnly = _.map(hierarchyValuesList, a => a.productHierarchyName);
    return valuesListOnly.length === _.compact(valuesListOnly).length;
  }


  isAllCombinationUniq(hierarchyValuesList) {
    let isValidCombination = true;
    const newHierarchyValues = _.map(_.cloneDeep(_.orderBy(hierarchyValuesList, ['productHierarchyLevel'], ['asc'])), a => a.productHierarchyName.toLowerCase());
    if (newHierarchyValues.length === _.uniq(newHierarchyValues).length) {
      return true;
    } else {
      isValidCombination = false;
    }
    return isValidCombination;
  }

  onSubmit() {
    const isAllValuesAvailable = this.isAllHierarchyValueAvailable(this.hierarchyLevels);
    const isAllCombinationUniq = isAllValuesAvailable && this.isAllCombinationUniq(this.hierarchyLevels);

    if (this.hierarchyLevels.length >= this.productHierarchyService.productHierarchyMinimumLevel) {
      if (isAllValuesAvailable && isAllCombinationUniq) {
        const payload = {
          productHierarchyMetadataList: this.hierarchyLevels,
          productHierarchyNodeMetadataList: this.hierarchyNodes,
        };
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: {
            payload: payload,
            confirmationMessage: this.isDisable !== true ? 'Hierarchy levels can only be selected once, please confirm before saving.' : ''
          }
        });
        this.dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.productHierarchyService.UpdateProductHierarchyLIst(payload).subscribe(res => {
              if (res) {
                this.isDisable = res;
                this.toastr.success('Success', 'Product Hierarchy Created successfully.');
              }
            },
              err => {
                this.toastr.error('Error', 'System failed to Created Product Hierarchy.');
              });
          }
        });
      } else {
        if (!isAllValuesAvailable || !isAllCombinationUniq) {
          this.toastr.error('Error', 'Please fill data in correct format.');
        }
      }
    } else {
      this.toastr.error('Error', 'Please Add minimum two hierarchy levels.');
    }

  }


  addHierarchyLevel() {
    this.hierarchyLevels.push({
      productHierarchyDisplayName: '',
      productHierarchyLevel: this.hierarchyLevels.length + 1,
      hierarchyName: '',
      active: true,
    });
  }

  deleteHierarchyLevel(event) {
    this.hierarchyLevels = _.remove(this.hierarchyLevels, level => level.productHierarchyLevel !== event);
    let hierarchyLevelIndex = 1;
    this.hierarchyLevels.forEach(hierarchy => {
      hierarchy.productHierarchyLevel = hierarchyLevelIndex;
      hierarchyLevelIndex = hierarchyLevelIndex + 1;
    });
  }

}
