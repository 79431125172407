import { TreeviewItem } from 'ngx-treeview';

export const CreateLocationHierarchyTree = (locationData, select: any = []) => {
  let treeList = [];
  treeList = list_to_tree(locationData);
  let tree = [];
  treeList.forEach((obj) => {
    let data = formTreeView(obj, select);
    tree.push(data);

  });
  return tree;
};

// create nth nested object of tree view items
const formTreeView = (obj, select) => {
  return new TreeviewItem({
    text: obj.locationHierarchyValue,
    value: obj.locationHierarchyId,
    collapsed: true,
    checked: select.includes(obj.locationHierarchyId),
    children: obj.children && obj.children.length && obj.children.map((el) => {
      return formTreeView(el, select);
    })
  });
}

const list_to_tree = (list) => {
  let map = {};
  let node;
  const roots = [];

  for (let i = 0; i < list.length; i++) {
    map[list[i].locationHierarchyId] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (let i = 0; i < list.length; i++) {
    node = list[i];
    if (node.locationParentId && node.locationParentId !== 0) {
      // if you have check that map[node.locationParentId] exists..
      list[map[node.locationParentId]].children.push(node);
      // }
    } else {
      roots.push(node);
    }
  }
  return roots;
}
