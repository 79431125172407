<br>
<div id="fb-forecast" class="container-fluid" style="height:550px">
  <div id="fb-forecast-new-planner" class="container-fluid" style="margin-left:185px">
    <div class="btn-wrapper">
      <div class="form-group">
        <label for="locationHierarchy">Location Hierarchy</label>
        <app-treeview-account-dropdown [type]="'LocationHierarchy'" [items]="locationHierarchyTreeList"
          (valueChange)="setLocationHierarchyValue($event)">
        </app-treeview-account-dropdown>
      </div>
    </div>
  </div>
  <div class="fb-forecast-accordion item-wrapper-panel">
    <div class="item-wrapper">
      <form [formGroup]="locationSetupForm">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="node1">{{locationHierarchyService.getLocationNodeLabel(1)}}</label>
                  <input type="text" readonly formControlName="node1" />
                </div>
                <div class="form-group">
                  <label for="launchDate">Launch Date</label>
                  <mat-form-field>
                    <input matInput [matDatepicker]="launchDate" name="launchDate" formControlName="launchDate"
                      (dateChange)="launchDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="launchDate"></mat-datepicker-toggle>
                    <mat-datepicker #launchDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <label for="otherInformation">Other Information</label>
                  <input type="text" formControlName="otherInformation" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="node2">{{locationHierarchyService.getLocationNodeLabel(2)}}</label>
                  <input type="text" formControlName="node2" />
                </div>
                <div class="form-group">
                  <label for="closingDate">Closing Date</label>
                  <mat-form-field>
                    <input matInput [matDatepicker]="closingDate" name="closingDate" formControlName="closingDate"
                      [min]="closingDateMin">
                    <mat-datepicker-toggle matSuffix [for]="closingDate"></mat-datepicker-toggle>
                    <mat-datepicker #closingDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <label for="status">Status</label>
                  <mat-slide-toggle formControlName="active"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrappers button-position">
          <button class="btn btn-primary" (click)="onSubmit()">Save</button>
        </div>
      </form>
    </div>
  </div>

</div>
